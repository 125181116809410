import { observer } from 'mobx-react-lite'
import React, { useCallback, useLayoutEffect } from 'react'


import { useStore } from '../../stores'
import { getWindowHeight, getWindowWidth } from '../../common'


interface WindowSizeHandlerProps { }


/**
 * Handles container inner width and height size
 */
const WindowSizeHandler: React.FC<WindowSizeHandlerProps> = (props) => {
  const store = useStore()

  const setSize = useCallback(() => {
    const w = getWindowWidth()
    const h = getWindowHeight()

    store.setWidth(w)
    store.setHeight(h)
  }, [store])

  useLayoutEffect(() => {
    window.addEventListener('resize', setSize)
    setSize()

    return () => {
      window.removeEventListener('resize', setSize)
    }
  }, [setSize])

  return null
}

export default observer(WindowSizeHandler)
