export const getWindowWidth = (): number => {
  return window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
}

export const getWindowHeight = (): number => {
  return window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
}
