import React, { useState } from 'react';
import PropTypes from 'prop-types';


import { Button, Input, Modal } from 'rsuite';
import ErrorBlock from '../ErrorBlock';


const ConfirmModal = (props) => {
  const [confirmPhrase, setConfirmPhrase] = useState('')

  const onConfirm = () => {
    if (typeof props.onConfirm !== 'function') return

    if (props.phrase != null && props.phrase !== confirmPhrase) {
      // TODO show alert
      console.info('not the same', props.phrase, confirmPhrase)
      return
    }

    props.onConfirm()
  }

  const onChange = (value) => {
    setConfirmPhrase(value)
  }

  const onClose = () => {
    if (typeof props.onClose !== 'function') return
    props.onClose()
  }

  return <Modal backdrop='static' show={props.show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>{props.title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div>{props.message}</div>
      {props.phrase && <Input value={confirmPhrase} onChange={onChange} />}

      {props.error && <ErrorBlock error={props.error} />}
    </Modal.Body>

    <Modal.Footer>
      <Button onClick={onConfirm} appearance="primary">{props.strings.confirmButton}</Button>
      <Button onClick={onClose} appearance="subtle">{props.strings.cancelButton}</Button>
    </Modal.Footer>
  </Modal>
}

ConfirmModal.displayName = 'ConfirmModal'

ConfirmModal.propTypes = {
  loading: PropTypes.bool,
  // TODO error
  title: PropTypes.string,
  message: PropTypes.string,
  phrase: PropTypes.string,
  error: PropTypes.string,
  show: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  strings: PropTypes.object,
}

ConfirmModal.defaultProps = {
  title: 'Подтвердите действие',
  strings: {
    confirmButton: 'Подтвердить',
    cancelButton: 'Отменить',
  }
}

export default ConfirmModal
