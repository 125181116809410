import { makeAutoObservable, runInAction, toJS } from 'mobx';


import { apiCall, errors, prod } from '../common';
import { TUser } from './interfaces';
import { DomainStore } from './store';


export default class CoreStore {
    invoker: TUser | null
    current: TUser | null
    loading: boolean
    error: number | null
    changingUser: boolean
    isSideMenuOpen: boolean
    private domainStore: DomainStore

    constructor(domainStore: DomainStore) {
        makeAutoObservable(this)
        this.invoker = null
        this.current = null
        this.loading = false
        this.error = null
        this.changingUser = false
        this.isSideMenuOpen = false
        this.domainStore = domainStore

        this.setCurrent = this.setCurrent.bind(this)
        this.dropError = this.dropError.bind(this)
        this.dropStore = this.dropStore.bind(this)
        this.handleUnauthorized = this.handleUnauthorized.bind(this)
        this.setSideMenuState = this.setSideMenuState.bind(this)
        this.call = this.call.bind(this)
        this.signIn = this.signIn.bind(this)
        this.signOut = this.signOut.bind(this)
        this.getInvoker = this.getInvoker.bind(this)
        this.updateInvoker = this.updateInvoker.bind(this)
        this.updateInvokerInfo = this.updateInvokerInfo.bind(this)
        this.restoreRequest = this.restoreRequest.bind(this)
        this.restoreCheck = this.restoreCheck.bind(this)
        this.restoreComplete = this.restoreComplete.bind(this)
    }

    setCurrent(user: TUser) {
        !prod && console.info('%cChanging user to %s', 'color: red;', user.userID)
        this.current = user

        this.domainStore.accounts.clear()
        this.domainStore.devices.clear()
        this.domainStore.groups.clear()
    }

    dropError() {
        this.error = null
        this.loading = false
    }

    dropStore() {
        this.invoker = null
        this.current = null
    }

    handleUnauthorized(error: any) {
        if (error.code === errors.unauthorized) {
            this.dropStore()
        }
    }

    setSideMenuState(state: boolean) {
        this.isSideMenuOpen = state
    }

    async call(method: string, params?: any) {
        this.loading = true
        this.error = null
        try {
            const resp = await apiCall(method, params)

            if (resp.error) {
                throw resp.error
            }

            runInAction(() => {
                this.loading = false
            })

            return resp.result
        } catch (err) {
            runInAction(() => {
                this.loading = false
                this.error = err
                if (err.code === errors.unauthorized) {
                    this.dropStore()
                }
            })

            !prod && console.info(method + ' exception', err)
            throw err
        }
    }

    async signIn(login: string, password: string) {
        await this.call('signin', { login: login, password: password })
        await this.getInvoker()
    }

    async signOut() {
        await this.call('signout')

        runInAction(() => {
            this.dropStore()
        })
    }

    async getInvoker() {
        const invoker = await this.call('users.current')

        runInAction(() => {
            this.invoker = invoker
            // this.setCurrent(invoker)
        })
    }

    async updateInvoker(user: TUser) {
        const updated = await this.call('users.update', user)

        runInAction(() => {
            this.invoker = {
                ...toJS(this.invoker),
                ...updated,
            }
        })
    }

    async updateInvokerInfo(user: TUser) {
        return this.updateInvoker(user)
    }

    async restoreRequest(token: string) {
        await this.call('restore.request', { token })
        return 'ok'
    }

    async restoreCheck(token: string) {
        await this.call('restore.check', { token })
        return 'ok'
    }

    async restoreComplete(token: string) {
        await this.call('restore.complete', { token })
        return 'ok'
    }
}
