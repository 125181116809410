import React from 'react';
import { Icon, Tooltip, Whisper } from 'rsuite';


interface HintProps {
  text: string,
}

const Hint: React.FC<HintProps> = ({ text }) => {
  const tooltip = (
    <Tooltip>
      {text}
    </Tooltip>
  );

  return <Whisper placement='auto' trigger='hover' speaker={tooltip}>
    <Icon icon='question2' />
  </Whisper>
};

Hint.displayName = 'Hint';

export default Hint;
