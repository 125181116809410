import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonToolbar, Form, FormGroup, Schema } from 'rsuite'


import FormElement from './FormElement';


const { StringType, NumberType } = Schema.Types;

const serverPortRegex = new RegExp('^([0-9]{1,4}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$')

const makeSchema = (fields, rules) => {
  const model = {}

  fields.forEach((field) => {
    if (!field.required) return
    const rule = rules[field.type]
    if (rule == null) return
    model[field.name] = rule
  })

  return Schema.Model(model);
}


// TODO external schema creation
const EntityForm = (props) => {
  const form = createRef()

  const rules = {
    string: StringType().isRequired(props.strings.requiredField),
    number: NumberType()
      .isInteger(props.strings.numberField)
      .pattern(serverPortRegex, props.strings.outOfRangeField)
      .isRequired(props.strings.requiredField)
  }

  const model = makeSchema(props.fields, rules)

  const handleSubmit = (valid, e) => {
    if (!valid) return

    console.info('Submit server form', form.current.state.formValue)

    if (typeof props.onSubmit === 'function') props.onSubmit(form.current.state.formValue)
  }

  const onChange = (record) => {
    if (typeof props.onChange === 'function') props.onChange(record)
  }

  console.info('Got fields', props.fields)

  return <Form fluid model={model} ref={form} onChange={onChange}>
    {props.fields.map((field, key) => {
      let value = ''
      if (props.values != null) {
        value = props.values[field.name] ? props.values[field.name] : ''
      }
      return <FormElement key={`formField-${key}`} entity={field} value={value} />
    })}

    <FormGroup>
      <ButtonToolbar>
        <Button appearance='primary' block onClick={handleSubmit}>
          {props.strings.submitButton}
        </Button>

        {(typeof props.onCancel === 'function') &&
          <Button appearance='subtle' block onClick={props.onCancel}>
            {props.strings.cancelButton}
          </Button>}

      </ButtonToolbar>
    </FormGroup>
  </Form>
}


EntityForm.displayName = 'EntityForm'

// TODO propTypes
EntityForm.propTypes = {
  fields: PropTypes.array,
  values: PropTypes.object,
  onEdit: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  strings: PropTypes.shape({}),
}

EntityForm.defaultProps = {
  strings: {
    submitButton: 'Сохранить',
    cancelButton: 'Отменить',
    requiredField: 'Это поле обязательно',
    numberField: 'Введите число',
    outOfRangeField: 'Введите корректный порт',
  }
}


export default EntityForm
