import { TDevice } from "../stores/interfaces"


export const makeDeviceLabel = (device: TDevice): string => {
  let label = device.imei
  const hasModel = device.model.length > 0
  const hasNumber = device.number.length > 0
  if (hasModel) {
    label = `${device.model} [${label}]`
  } else if (hasNumber) {
    label = `${device.number} [${label}]`
  } else if (hasModel && hasNumber) {
    label = `${device.model} - ${device.number} [${label}]`
  }
  return label
}

export const getServerVersion = (numVersion: number): string => {
  // MinTelematicVersionCode = ((3 & 0xFF) << 24) | ((1 & 0xFF) << 16) | 271&0xFFFF
  if (numVersion == null || numVersion === 0) {
    return '-'
  }

  return `${(numVersion >> 24) & 0xFF}.${(numVersion >> 16) & 0xFF}.${numVersion & 0xFFFF}`
}
