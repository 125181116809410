import { makeAutoObservable, runInAction, toJS } from 'mobx';


import { apiCall, errors, prod } from '../common';
import { TUser } from './interfaces';


const entity = 'users'


const filterFunc = (filter: string) => {
    return (user: TUser) =>
        user.login.toLowerCase().indexOf(filter) !== -1 ||
        user.email.toLowerCase().indexOf(filter) !== -1 ||
        user.label.toLowerCase().indexOf(filter) !== -1 ||
        user.firstName.toLowerCase().indexOf(filter) !== -1 ||
        user.middleName.toLowerCase().indexOf(filter) !== -1 ||
        user.lastName.toLowerCase().indexOf(filter) !== -1
}


export default class UsersStore {
    private originalList: TUser[]
    filter: string
    loading: boolean
    error: number | null

    constructor() {
        makeAutoObservable(this)

        this.originalList = []
        this.filter = ''
        this.loading = false
        this.error = null

        this.dropError = this.dropError.bind(this)
        this.dropStore = this.dropStore.bind(this)
        this.setFilter = this.setFilter.bind(this)
        this.getByID = this.getByID.bind(this)
        this.getLabel = this.getLabel.bind(this)
        this.addUser = this.addUser.bind(this)
        // this.updateUserInfo = this.updateUserInfo.bind(this)

        this.clients = this.clients.bind(this)
        this.filteredClients = this.filteredClients.bind(this)

        this.handleUnauthorized = this.handleUnauthorized.bind(this)
        this.call = this.call.bind(this)
        this.fetchClients = this.fetchClients.bind(this)
        this.fetchStaff = this.fetchStaff.bind(this)
        this.fetchUser = this.fetchUser.bind(this)
        this.createUser = this.createUser.bind(this)
        this.updateUser = this.updateUser.bind(this)
        // this.updateInfo = this.updateInfo.bind(this)
    }

    dropError() {
        this.error = null
        this.loading = false
    }

    dropStore() {
        this.originalList = []
    }

    setFilter(filter: string) {
        this.filter = filter
    }

    getByID(id: string) {
        return this.originalList.find((u) => u.userID === id)
    }

    getLabel(id: string) {
        const user = this.getByID(id)
        if (user == null) return '-'

        let title = user.label
        if (title.length === 0) {
            if (user.firstName.length > 0 || user.lastName.length > 0) {
                title = user.firstName + ' ' + user.lastName
            } else {
                title = user.login.length > 0 ? user.login : user.email
            }
        }
        return title
    }

    private addUser(user: TUser) {
        const index = this.originalList.findIndex((u) => u.userID === user.userID)
        if (index === -1) {
            this.originalList.push(user)
        } else {
            this.originalList[index] = user
        }
    }

    // private updateUserInfo(userID: string, userInfo: TUserInfo) {
    //     const index = this.originalList.findIndex((u) => u.userID === userID)
    //     if (index !== -1) {
    //         this.originalList[index] = {
    //             ...this.originalList[index],
    //             userInfo: userInfo,
    //         }
    //     }
    // }

    clients(userID: string): TUser[] {
        if (userID.length === 0) return []
        return this.originalList.filter((u) => u.ownerID === userID && !u.isStaff)
    }

    filteredClients(userID: string): TUser[] {
        return this.clients(userID).filter(filterFunc(this.filter))
    }

    staff(userID: string): TUser[] {
        if (userID.length === 0) return []
        return this.originalList.filter((u) => u.ownerID === userID && u.isStaff === true)
    }

    filteredStaff(userID: string): TUser[] {
        return this.staff(userID).filter(filterFunc(this.filter))
    }

    handleUnauthorized(error: any) {
        if (error.code === errors.unauthorized) {
            this.dropStore()
        }
    }

    async call(method: string, params?: any) {
        this.loading = true
        this.error = null
        try {
            const resp = await apiCall(method, params)

            if (resp.error) {
                throw resp.error
            }

            runInAction(() => {
                this.loading = false
            })

            return resp.result
        } catch (err) {
            runInAction(() => {
                this.loading = false
                this.error = err
            })

            !prod && console.info(method + ' exception', err)
            throw err
        }
    }

    async count(userID: string, params?: any) {
        const count = await this.call(`${entity}.count`, { userID: userID, ...params })
        return count
    }


    async fetchClients(userID: string) {
        const resp = await this.call('users.list', { userID: userID })

        runInAction(() => {
            resp.forEach((u: TUser) => {
                this.addUser(u)
            })
        })
        return resp
    }

    async fetchStaff(userID: string) {
        const resp = await this.call(`${entity}.list`, { userID: userID, isStaff: true })

        runInAction(() => {
            resp.forEach((u: TUser) => {
                this.addUser(u)
            })
        })

        return resp
    }

    async fetchUser(userID: string) {
        const resp = await this.call(`${entity}.get`, { userID: userID })

        runInAction(() => {
            this.addUser(resp)
        })

        return resp
    }

    async createUser(user: TUser) {
        const resp = await this.call(`${entity}.create`, user)

        runInAction(() => {
            this.addUser(resp)
        })

        return resp
    }

    async updateUser(user: TUser) {
        const resp = await this.call(`${entity}.update`, user)

        runInAction(() => {
            this.addUser(resp)
        })

        return resp
    }

    // async updateInfo(userID: string, userInfo: TUserInfo) {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             const resp = await this.call('usersInfo.update', { userID: userID, userInfo: userInfo })

    //             runInAction(() => {
    //                 this.updateUserInfo(userID, resp)
    //             })
    //             resolve(resp)
    //         } catch (err) {
    //             reject(err)
    //         }
    //     })
    // }

    async fetchAndGet(userID: string): Promise<TUser> {
        const user = this.originalList.find((c) => c.userID === userID)
        if (user != null) {
            return toJS(user)
        }

        const newUser = await this.call('users.get', { userID: userID })

        runInAction(() => {
            this.addUser(newUser)
        })

        return newUser
    }

    async remove(userID: string): Promise<TUser> {
        const resp = await this.call('users.delete', { userID: userID })

        runInAction(() => {
            const index = this.originalList.findIndex((u) => u.userID === userID)
            if (index !== -1) {
                this.originalList.splice(index, 1)
                this.originalList = [...this.originalList]
            }
        })

        return resp
    }
}
