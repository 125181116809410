import React, { useState } from 'react';
import { Button, Message } from 'rsuite';


import styles from './ErrorBlock.module.css';


interface ErrorBlockProps {
  title?: string,
  error: string,
  closable?: boolean,
  actionCaption?: string,
  action?: (event: React.SyntheticEvent) => void,
}


const ErrorBlock = (props: ErrorBlockProps) => {
  const [hidden, setHidden] = useState(false)
  const onClose = () => {
    setHidden(true)
  }

  if (hidden) {
    return null
  }

  return <div className={styles.section}>
    <Message
      closable={props.closable}
      onClose={onClose}
      className={styles.block}
      type='error'
      title={props.title != null && props.title.length > 0 && props.title}
      description={
        <p>
          {props.error}
          <br />
          {props.action && <Button appearance='link' onClick={props.action}>{props.actionCaption}</Button>}
        </p>
      }
    />
  </div>
};

export default ErrorBlock;
