import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'rsuite'


import styles from './Dimmer.module.css'


export const Dimmer = (props) => {
  let className = styles.spinnerContainer

  let dark = false
  if (props.backdrop != null) {
    // eslint-disable-next-line default-case
    switch (props.backdrop) {
      case 'dark':
        dark = true
        className += ' ' + styles.dark
        break;

      case 'light':
        className += ' ' + styles.light
        break;
    }
  }

  const size = ((size) => {
    // eslint-disable-next-line default-case
    switch (size) {
      case 'lg':
        return ['5x', '4x', '3x']

      case 'md':
        return ['4x', '3x', '2x']

      case 'sm':
        return ['3x', '2x', undefined]
    }
  })(props.size)

  return <div className={className}>
    <div className={props.leftShift ? styles.leftShift : ''}>
      <Icon spin icon='cog' size={size[0]} className={dark ? styles.lightIcon : ''} />
      <Icon className={`${styles.iconReverseSpin}${dark ? ' ' + styles.lightIcon : ''}`} icon='cog' size={size[1]} />
      <Icon spin icon='cog' size={size[2]} className={dark ? styles.lightIcon : ''} />
    </div>
  </div>
}

Dimmer.propTypes = {
  backdrop: PropTypes.oneOf(['dark', 'light']),
  size: PropTypes.oneOf(['lg', 'md', 'sm']),
  leftShift: PropTypes.bool,
}

Dimmer.defaultProps = {
  size: 'md',
}
