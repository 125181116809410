import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

// import useWindowSize from '../hooks/useWindowSize';
import PrivateSection from './PrivateSection';
import { Dimmer, WindowSizeHandler } from '../components';
import { slugs } from '../common'
import { useStore } from '../stores'


const LoginPage = lazy(() => import('../pages/LoginPage'));
const RestorePage = lazy(() => import('../pages/RestorePage'));


// TODO replace users' store method getInvoker with on load method inside
// this component
const Routes = ({ strings }) => {
    const store = useStore()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        store.core.getInvoker()
            .then(() => {
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
        return () => {
            store.core.dropStore()
        }
    }, [store.core])

    if (loading) {
        return <Dimmer />
    }

    // if (store.users.error) {
    //     return <div>{`Response error. Code ${apiError.code}, message ${apiError.message}`}</div>
    // }

    return <Suspense fallback={<Dimmer />}>
        <WindowSizeHandler />
        <Switch>
            <Route path={slugs.login} component={LoginPage} />
            <Route path={slugs.restore} component={RestorePage} />
            <PrivateSection />
            {/* <Redirect to={slugs.login} /> */}
        </Switch>
    </Suspense>
}

export default withRouter(observer(Routes));

Routes.propTypes = {
    strings: PropTypes.shape({
        errors: PropTypes.shape({
            basic: PropTypes.string,
            invalidCredentials: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

Routes.defaultProps = {
    strings: {
        errors: {
            basic: 'Basic error',
            invalidCredentials: 'Invalid credentials',
        },
    },
}