import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, IconButton } from 'rsuite'


import styles from './FloatingButton.module.css'


type TIcon = 'plus' | 'refresh' | 'chevron-left' | 'save' | 'eraser'


interface FloatingButtonProps {
  onClick?: () => void
  href?: string
  hint?: string
  icon?: TIcon
  color?: 'main' | 'gray' | 'red'
  disabled?: boolean
}

const colors = {
  main: styles.colorMain,
  gray: styles.colorGray,
  red: styles.colorRed,
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ href, hint, icon, color, disabled, onClick }) => {
  const currentIcon = icon != null ? icon : 'plus'
  const currentColor = color != null ? colors[color] : colors.main

  const iconBtn = <IconButton
    disabled={disabled}
    onClick={onClick != null ? onClick : undefined}
    title={hint}
    appearance='primary'
    circle
    icon={<Icon icon={currentIcon} />}
    className={`${styles.floatingButton} ${currentColor}`}
  />

  const btn = href != null && href.length > 0
    ? <Link to={href} className={styles.simpleLink}>
      {iconBtn}
    </Link>
    : iconBtn

  return btn
}

export default FloatingButton


export const FloatingButtonContainer: React.FC = (props) => {
  return <div className={styles.floatingButtonContainer}>
    {props.children}
  </div>
}
