import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'rsuite';
import ruRU from 'rsuite/lib/IntlProvider/locales/ru_RU';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";


import { StoreProvider } from './stores/context';
import Routes from './routes';


import general from './common/i18n/ru/general.json';
import devices from './common/i18n/ru/devices.json';
import servers from './common/i18n/ru/servers.json';
import accounts from './common/i18n/ru/accounts.json';
import clients from './common/i18n/ru/clients.json';
import users from './common/i18n/ru/users.json';
import groups from './common/i18n/ru/groups.json';
import reports from './common/i18n/ru/reports.json';
import errors from './common/i18n/ru/errors.json';


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'ru',
  fallbackLng: 'ru',
  resources: {
    ru: {
      general: general,
      devices: devices,
      servers: servers,
      accounts: accounts,
      clients: clients,
      users: users,
      groups: groups,
      reports: reports,
      errors: errors,
    },
  },
});

function formatDate(data, formatStr) {
  return format(data, formatStr, { locale: ru });
}

// TODO add global storage
const App = () => (
  <StoreProvider>
    <I18nextProvider i18n={i18next}>
      <IntlProvider locale={ruRU} formatDate={formatDate}>
        <Router>
          <Routes />
        </Router>
      </IntlProvider>
    </I18nextProvider>
  </StoreProvider>
);

export default App;
