import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Input, InputGroup, Icon } from 'rsuite';

export const InputPassword = (props) => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState('password')

  const showIcon = show ? 'eye' : 'eye-slash';

  const toggleType = () => {
    if (show) {
      setShow(false)
      setType('password')
    } else {
      setShow(true)
      setType('text')
    }
  }

  return <InputGroup inside style={props.style}>
    {props.icon && <InputGroup.Addon>
      <Icon icon={props.icon} />
    </InputGroup.Addon>}

    <Input type={type} {...props} />

    <InputGroup.Button onClick={toggleType}>
      <Icon icon={showIcon} />
    </InputGroup.Button>

  </InputGroup>
}

InputPassword.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
}

InputPassword.defaultProps = {
  name: 'password',
}
