import React from 'react'
import { Drawer } from 'rsuite'


interface Props {
  show?: boolean
  full?: boolean
  title?: string
  controls?: React.ReactNode
  onHide?: () => void
}


const TagDrawer: React.FC<Props> = (props) => {
  const onHide = () => {
    if (props.onHide) {
      props.onHide()
    }
  }

  return <Drawer show={props.show} onHide={onHide} size={'xs'} full={props.full}>
    <Drawer.Header>
      <Drawer.Title>{props.title}</Drawer.Title>
    </Drawer.Header>

    <Drawer.Body>
      {props.children}
    </Drawer.Body>

    <Drawer.Footer>
      {props.controls}
    </Drawer.Footer>
  </Drawer>
}

export default TagDrawer
