import React from 'react'
import ModalOverlay, { RenderModalBackdropProps } from 'react-overlays/Modal'


import ScrollWrapper from '../ScrollWrapper'


import styles from './Modal.module.css'


interface Props {
  show: boolean
  header?: string
  onHide: () => void
}


export const Modal: React.FC<Props> = (props) => {
  return <ModalOverlay
    show={props.show}
    onHide={props.onHide}
    renderBackdrop={renderBackdrop}
    aria-labelledby="modal-label"
    className={styles.modal}
  >
    <>
      <div className={styles.closer} onClick={props.onHide}><span>×</span></div>

      {props.header != null && props.header.length > 0 && <div className={styles.header}>
        <div className={styles.title}>{props.header}</div>
      </div>}

      <div className={styles.inner}>
        <ScrollWrapper>
          {props.children}
        </ScrollWrapper>
      </div>
    </>
  </ModalOverlay>
}


const renderBackdrop = (props: RenderModalBackdropProps) => <div className={styles.backdrop} {...props} />;

export default Modal
