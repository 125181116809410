import React, { useEffect, useState } from 'react'
import { RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Dropdown, Icon, IconButton, Input } from 'rsuite';


import { ErrorBlock, FloatingControl, Dimmer } from '../../components';
import { TagButton } from '../TagButton';
import { getErrorMessage, errors, sortBy } from '../../common';
import { DeviceFilterDrawer } from './FilterDrawer';
import { useStore } from '../../stores';
import { TAttribute, TCategory, TDevice } from '../../stores/interfaces';


import styles from './WithDevices.module.css';


interface Props extends RouteComponentProps {
  sortFields?: TFilter[]
  loading?: boolean
  onFilterChange?: (enabled?: boolean) => void
  onGroupEditOn?: () => void
  onGroupEditOff?: () => void
}

type TFilter = 'blackDate' | 'endOfServiceDate' | 'lastActivity'
type TDAttrIndex = { [key: string]: number[] }


const sortButtons = [
  { name: 'imei', label: 'Номер Терминала' },
  { name: 'model', label: 'Марка/Модель' },
  { name: 'number', label: 'Номер ТС' },
  { name: 'endOfServiceDate', label: 'Черная дата' }, // Nearest end of service date
  { name: 'blackDate', label: 'Черная дата' },
  { name: 'lastActivity', label: 'Активность' },
]

const defaultSorters = ['imei', 'model', 'number']

const filterFunc = (filter: string) => (device: TDevice) => {
  const lfilter = filter.toLowerCase()
  return device.imei.toLowerCase().indexOf(lfilter) !== -1 ||
    device.model.toLowerCase().indexOf(lfilter) !== -1 ||
    device.number.toLowerCase().indexOf(lfilter) !== -1
}

const tagFilter = (
  index: TDAttrIndex | null,
  categories: TCategory[] | null,
  attributes: TAttribute[] | null,
  selected: number[],
) => (d: TDevice): boolean => {

  if (index == null) {
    return true
  }

  if (categories == null || categories.length === 0) {
    return true
  }

  if (attributes == null || attributes.length === 0) {
    return true
  }

  const noSelected = Object.keys(selected).length === 0
  if (noSelected) {
    return true
  }

  const deviceAttributes = index[d.deviceID]
  if (deviceAttributes == null) {
    if (!noSelected) {
      return false
    }
    return true
  }

  // Expanding selected into cat-attrs map
  const sIndex: TDAttrIndex = {}

  selected.forEach((aid) => {
    const a = attributes.find((attr) => attr.attributeID === aid)
    if (a == null) {
      return
    }

    const c = categories.find((cat) => cat.categoryID === a.categoryID)
    if (c == null) {
      return
    }

    if (sIndex[c.categoryID] == null) {
      sIndex[c.categoryID] = []
    }

    sIndex[c.categoryID].push(a.attributeID)
  })

  for (let [, attrs] of Object.entries(sIndex)) {
    let match = false
    for (let i = 0; i < deviceAttributes.length; i++) {
      if (attrs.includes(deviceAttributes[i])) {
        match = true
        break
      }
    }

    if (!match) {
      return false
    }
  }
  return true
}

interface FilledCategory {
  categoryID: number
  label: string
  attributes: TAttribute[]
}

const reduceSelectedTags = (selected: number[], cats?: TCategory[] | null, attrs?: TAttribute[] | null): FilledCategory[] => {
  if (cats == null || cats.length === 0) {
    return []
  }

  if (attrs == null || attrs.length === 0) {
    return []
  }

  const sIndex: FilledCategory[] = []

  selected.forEach((aid) => {
    const a = attrs.find((attr) => attr.attributeID === aid)
    if (a == null) {
      return
    }

    const c = cats.find((cat) => cat.categoryID === a.categoryID)
    if (c == null) {
      return
    }

    const idx = sIndex.findIndex((fc) => fc.categoryID === c.categoryID)
    if (idx === -1) {
      sIndex.push({
        categoryID: c.categoryID,
        label: c.label,
        attributes: [a],
      })
    } else {
      sIndex[idx].attributes.push(a)
    }
  })

  return sIndex
}

const WithDevices: React.FC<Props> = ({ match, ...props }) => {
  const store = useStore()
  const { t } = useTranslation('devices')
  const history = useHistory()
  const location = useLocation()
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const [groupEdit, setGroupEdit] = useState<boolean>(false)

  const loading = props.loading || store.accounts.loading || store.groups.loading
  let error = null
  let code = -1
  if (store.accounts.error != null) {
    code = store.accounts.error.code
    error = getErrorMessage(store.accounts.error, 'Ошибка при получении аккаунтов')
  } else if (store.groups.error != null) {
    code = store.groups.error.code
    error = getErrorMessage(store.groups.error, 'Ошибка при получении информации о группах')
  } else if (store.devices.error != null) {
    code = store.devices.error.code
    error = getErrorMessage(store.devices.error, 'Ошибка при запросе списка устройств')
  }

  if (error != null && error.length > 0 && code === errors.accessDenied) {
    error = ''
  }

  const params = new URLSearchParams(location.search)
  const sortColumn = params.get('sort') || 'imei'
  const sortAsc = params.get('sortOrder') !== 'desc'
  const searchString = params.get('search') || ''

  const selectedAccounts = params.getAll('accounts')
  const selectedTags = params.getAll('tags').map((st) => parseInt(st))

  const allowedSorters = [...defaultSorters]
  if (props.sortFields != null) {
    allowedSorters.push(...props.sortFields)
  }

  useEffect(() => {
    return () => {
      store.devices.setFilter('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFilterChange = () => {
    if (props.onFilterChange != null) {
      props.onFilterChange(selectedTags.length > 0 || selectedAccounts.length > 0)
    }
  }

  const onSearch = (search: string) => {
    if (search.length === 0) {
      params.delete('search')
    } else {
      params.set('search', search)
    }

    history.push({ search: params.toString(), hash: location.hash })
    store.devices.setFilter(search)

    onFilterChange()
  }

  const openDrawer = () => {
    setShowDrawer(true)
  }

  const hideDrawer = () => {
    setShowDrawer(false)
  }

  const onTagsChange = (selected: number[]) => {
    setSelectedTags(selected)
    onFilterChange()
  }

  const setSelectedAccounts = (accounts: string[]) => {
    params.delete('accounts')
    if (accounts.length > 0) {
      accounts.map((a) => params.append('accounts', a))
    }
    history.push({ search: params.toString(), hash: location.hash })
  }

  const setSelectedTags = (attributes: number[]) => {
    params.delete('tags')
    if (attributes.length > 0) {
      attributes.map((a) => params.append('tags', a.toString()))
    }
    history.push({ search: params.toString(), hash: location.hash })
  }

  const onAccountsChange = (accounts: string[]) => {
    setSelectedAccounts(accounts)
    onFilterChange()
  }

  const devices = store.devices.list
    .filter((d: TDevice) => selectedAccounts.length === 0 || selectedAccounts.includes(d.accountID))
    .filter(tagFilter(
      store.groups.devicesIndex,
      store.groups.categories,
      store.groups.attributes,
      selectedTags,
    ))
    .filter(filterFunc(searchString))
    .sort(sortBy(sortColumn, sortAsc))

  const filterUsed = devices.length !== store.devices.list.length && (selectedAccounts.length > 0 || Object.keys(selectedTags).length > 0)
  const elements = React.Children.toArray(props.children)

  const dropAllFilters = () => {
    dropAccounts()
    setSelectedTags([])
  }

  const dropAccounts = () => {
    setSelectedAccounts([])
  }

  const dropCat = (aids: number[]) => {
    const nextSelected = selectedTags.filter((tag) => aids.indexOf(tag) < 0)
    setSelectedTags(nextSelected)
  }

  const toggleGroupEdit = () => {
    if (groupEdit) {
      setGroupEdit(false)
      if (props.onGroupEditOff) {
        props.onGroupEditOff()
      }
    } else {
      setGroupEdit(true)
      if (props.onGroupEditOn) {
        props.onGroupEditOn()
      }
    }
  }

  const tagButtons = reduceSelectedTags(selectedTags, store.groups.categories, store.groups.attributes)

  let singleAccount = 'Аккаунт'
  if (store.accounts.list != null && store.accounts.list.length > 0 && selectedAccounts.length === 1) {
    const idx = store.accounts.list.findIndex((a) => a.accountID === selectedAccounts[0])
    if (idx !== -1) {
      singleAccount = store.accounts.list[idx].title
    }
  }

  return <>
    <FloatingControl>
      <div className={styles.controlWrapper}>
        <div className={styles.searchWrapper}>
          {store.devices.originalList.length > 0 &&
            <Input placeholder={t('list.searchPlaceholder')} value={searchString} onChange={onSearch} />}
        </div>

        <div className={styles.buttonsWrapper}>
          <Dropdown title={'Сортировка'} renderTitle={(children) => {
            return <IconButton appearance='ghost' size='sm' icon={
              sortAsc ? <Icon icon='sort-amount-asc' /> : <Icon icon='sort-amount-desc' />}>
              {children}
            </IconButton>;
          }}>
            {sortButtons.map((btn) => {
              if (allowedSorters.length === 0) {
                return null
              }

              if (!allowedSorters.includes(btn.name)) {
                return null
              }

              const selected = sortColumn === btn.name
              const toggleSorting = () => {
                if (selected) {
                  if (sortAsc) {
                    params.set('sortOrder', 'desc')
                  } else {
                    params.delete('sortOrder')
                  }
                } else {
                  params.delete('sortOrder')
                  params.set('sort', btn.name)

                  if (btn.name === 'imei') {
                    params.delete('sort')
                  }
                }
                history.push({ search: params.toString(), hash: location.hash })
              }

              return <Dropdown.Item
                key={btn.name}
                active={selected}
                icon={selected ? sortAsc ? <Icon icon='sort-up' /> : <Icon icon='sort-desc' /> : undefined}
                onClick={toggleSorting}
              >
                {btn.label}
              </Dropdown.Item>
            })}
          </Dropdown>

          <IconButton
            size='sm'
            icon={<Icon icon='filter' />}
            appearance='ghost'
            title='Фильтр'
            onClick={openDrawer}
            color={filterUsed ? 'orange' : undefined}
          >Фильтр</IconButton>

          {props.onGroupEditOn != null &&
            <IconButton
              size='sm'
              icon={<Icon icon='edit2' />}
              appearance='ghost'
              title='Группировка'
              onClick={toggleGroupEdit}
              color={groupEdit ? 'orange' : undefined}
            >Группировка</IconButton>
          }
        </div>

        {(selectedAccounts.length > 0 || tagButtons.length > 0) && <div className={styles.buttonsWrapper}>
          <TagButton clear onClick={dropAllFilters}>{'Сбросить фильтры'}</TagButton>

          {selectedAccounts.length > 0 && <TagButton onClick={dropAccounts}>
            {selectedAccounts.length > 1 ? `Аккаунты: ${selectedAccounts.length}` : singleAccount}
          </TagButton>}

          {tagButtons.map((fc) => {
            return <TagButton key={fc.categoryID} onClick={() => dropCat(fc.attributes.map((a) => a.attributeID))}>
              {fc.attributes.length > 1 ? `${fc.label}: ${fc.attributes.length}` : `${fc.label}: ${fc.attributes[0].label}`}
            </TagButton>
          })}
        </div>}
      </div>
    </FloatingControl>

    <DeviceFilterDrawer
      title={'Фильтрация устройств'}
      accounts={store.accounts.list}
      categories={store.groups.categories}
      attributes={store.groups.attributes}
      show={showDrawer}
      onHide={hideDrawer}
      onAccountsChange={onAccountsChange}
      selectedAccounts={selectedAccounts}
      onTagsChange={onTagsChange}
      selectedTags={selectedTags}
    />

    {error != null && <ErrorBlock error={error} />}

    {loading && <Dimmer leftShift backdrop='light' />}

    {!error && elements != null && elements.map((e) => {
      if (React.isValidElement(e)) {
        return React.cloneElement(e, {
          devices: devices,
          loading: loading,
          filtered: selectedAccounts.length > 0 || selectedTags.length > 0,
        })
      }
      return e
    })}
  </>
}

export default withRouter(observer(WithDevices))
