import React from 'react'
import { FormControl, FormGroup, SelectPicker } from 'rsuite';

const FormElement = ({ entity, value }) => {
  if (entity == null) return null
  switch (entity.element) {
    case 'input':
      return <FormGroup>
        <FormControl
          name={entity.name}
          value={value}
          checkTrigger='blur'
          placeholder={entity.placeholder}
          autoComplete='none'
        />
      </FormGroup>

    case 'select':
      return <FormGroup>
        <FormControl
          name={entity.name}
          block
          accepter={SelectPicker}
          cleanable={false}
          searchable={false}
          data={entity.data}
          value={value}
          placeholder={entity.placeholder}
        />
      </FormGroup>

    default:
      return null
  }
}

export default FormElement
