import React from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { Button, Icon, Loader } from 'rsuite'


import styles from './EditableInput.module.css'


interface Props {
  value?: string
  newTitle?: string
  onEdit: (value: string) => Promise<string>
  onDelete?: () => void
}


const EditableInput: React.FC<Props> = (props) => {
  const isNew: boolean = props.value == null || props.value.length === 0
  const externalValue: string = isNew ? '' : props.value || ''

  const state = useLocalObservable(() => {
    return {
      label: externalValue,
      edit: false,
      loading: false,
      setLabel(label: string) {
        this.label = label
      },
      setEdit(isEdit: boolean) {
        this.edit = isEdit
      },
      setLoading(isLoading: boolean) {
        this.loading = isLoading
      },
    }
  })

  // const [label, setLabel] = React.useState<string>(externalValue)
  // const [edit, setEdit] = React.useState<boolean>(false)
  // const [loading, setLoading] = React.useState<boolean>(false)

  const inputRef = React.createRef<any>()

  React.useEffect(() => {
    return () => {
      document.body.removeEventListener('keydown', cancelHandler)
      document.body.removeEventListener('mousedown', cancelClickHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => {
    state.setEdit(false)
    state.setLabel(externalValue)
    document.body.removeEventListener('keydown', cancelHandler)
    document.body.removeEventListener('mousedown', cancelClickHandler)
  }

  const handleSubmit = () => {
    if (state.label.length === 0 || state.label === props.value) {
      state.setEdit(false)
      document.body.removeEventListener('keydown', cancelHandler)
      document.body.removeEventListener('mousedown', cancelClickHandler)
      return
    }

    state.setLoading(true)
    props.onEdit(state.label)
      .then(() => {
        state.setLoading(false)
        state.setEdit(false)
        document.body.removeEventListener('keydown', cancelHandler)
        document.body.removeEventListener('mousedown', cancelClickHandler)

        if (isNew) {
          state.setLabel('')
        }
      })
      .catch((err) => {
        state.setLoading(false)
      })
  }

  const cancelHandler = (evt: KeyboardEvent) => {
    switch (evt.key) {
      case 'Enter':
        handleSubmit()
        break

      case 'Escape':
        handleCancel()
        break
    }
  }

  const cancelClickHandler = (event: MouseEvent) => {
    if (event !== null && event.target instanceof HTMLElement) {
      const eID = event.target.dataset['id'] || ''
      if (['edit', 'save', 'cancel'].includes(eID)) {
        event.stopPropagation()
        event.preventDefault()
        return
      }
    }
    handleCancel()
  }

  React.useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.focus()
    }
  }, [inputRef])

  const toggleEditOn = (event: React.MouseEvent | React.SyntheticEvent) => {
    event.stopPropagation()
    document.body.addEventListener('keydown', cancelHandler)
    document.body.addEventListener('mousedown', cancelClickHandler)
    state.setEdit(true)
  }

  const onDelete = () => {
    if (props.onDelete != null) {
      props.onDelete()
    }
  }

  return <div className={styles.labelContainer} >
    <div className={styles.label}>
      {!state.edit && !isNew && <div className={styles.labelCaption} onClick={toggleEditOn}>{state.label}</div>}

      {!state.edit && isNew && <Button className={styles.createBtn} appearance='ghost' onClick={toggleEditOn}>
        + {props.newTitle}
      </Button>}

      {state.edit && <input
        className={styles.labelEdit}
        data-id='edit'
        ref={inputRef}
        value={state.label}
        onClick={(e: React.MouseEvent) => { e.stopPropagation(); e.preventDefault() }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => state.setLabel(evt.target.value)}
      />}
    </div>

    {state.loading && <Loader />}

    {state.edit && <Icon data-id='save' className={styles.saver} icon='save' onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      handleSubmit()
    }} />}

    {state.edit && <Icon data-id='cancel' className={styles.canceler} icon='close' onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      handleCancel()
    }} />}

    {!state.edit && props.onDelete != null && <Icon className={styles.remover} icon='trash-o' onClick={(e: React.MouseEvent) => {
      e.stopPropagation()
      e.preventDefault()
      onDelete()
    }} />}
  </div>
}

EditableInput.defaultProps = {
  newTitle: 'New',
}

export default observer(EditableInput)
