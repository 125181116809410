import React from 'react';


import styles from './FloatingControl.module.css'


interface FloatingControlProps { }


// TODO mabe make it trully floating with fixed prop?
// TODO make scroll spy with adding box shadow only on scroll
const FloatingControl: React.FC<FloatingControlProps> = (props) => {
  return <div className={styles.container}>
    <div className={styles.control}>
      {props.children}
    </div>
    {/* <div className={styles.pusher}></div> */}
  </div>
}

FloatingControl.displayName = 'FloatingControl'

export default FloatingControl
