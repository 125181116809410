import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader, SelectPicker } from 'rsuite';


import { useStore } from '../stores';
import { getErrorMessage } from '../common';
import ErrorBlock from './ErrorBlock';


interface ServersSelectProps {
  value: string | null
  disabled?: boolean
  onChange?: (value: any) => void
};


const ServersSelect: React.FC<ServersSelectProps> = (props) => {
  const store = useStore()

  const initialValue = props.value != null ? props.value : ''
  const [value, setValue] = useState<string>(initialValue)

  const onChange = (serverID: string) => {
    setValue(serverID)
    if (props.onChange != null) {
      props.onChange(serverID)
    }
  }

  let error: string | null = null
  if (store.servers.error != null) {
    error = getErrorMessage(store.servers.error, 'Ошибка при получении списка серверов')
  }

  return <div>
    {store.servers.loading && <Loader />}

    {error && <ErrorBlock
      title={'Не удалось загрузить список серверов'}
      error={error}
      actionCaption={'Попробовать снова'}
      action={() => store.servers.fetchList(store.core.current?.userID || '')}
    />}

    {!store.servers.loading && !error && <SelectPicker
      searchable={false}
      disabled={props.disabled}
      placeholder={'Сервер'}
      cleanable={false}
      block
      value={value}
      data={store.servers.list.map((server) => ({
        value: server.serverID,
        label: server.title,
      }))}
      onChange={onChange}
    />}
  </div>
};

ServersSelect.displayName = 'ServersSelect';

export default observer(ServersSelect);
