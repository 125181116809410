import { makeAutoObservable, runInAction } from 'mobx'
import { differenceInSeconds } from 'date-fns'


import api from '../api/api'
import { sortBy } from '../common/sort'
import { DomainStore } from './store';


import { TCategory, TAttribute, RPCError, TAttributeIndex } from './interfaces'


type TIndex = Map<string, number[]> // {[key: string]: number[]}


// TODO maybe should increase this period?
const reloadPeriod = 60


export default class GroupsStore {
  private categoriesList: TCategory[]
  private attributesList: TAttribute[]
  // private usersIndex: TIndex
  private devicesIndexRaw: TAttributeIndex // TIndex
  private catsLastFetch: Date
  private attrsLastFetch: Date
  private dIndexLastFetch: Date
  private domainStore: DomainStore
  loading: boolean
  error: RPCError | null

  constructor(domainStore: DomainStore) {
    this.categoriesList = []
    this.attributesList = []
    // this.usersIndex = new Map<string, number[]>()
    this.devicesIndexRaw = {} // new Map<string, number[]>()

    this.catsLastFetch = new Date(0)
    this.attrsLastFetch = new Date(0)
    this.dIndexLastFetch = new Date(0)

    this.domainStore = domainStore

    this.loading = false
    this.error = null

    makeAutoObservable(this, {}, { autoBind: true })
  }

  clear() {
    this.categoriesList = []
    this.attributesList = []
    // this.usersIndex = new Map<string, number[]>()
    this.devicesIndexRaw = {} // new Map<string, number[]>()

    this.catsLastFetch = new Date(0)
    this.attrsLastFetch = new Date(0)
    this.dIndexLastFetch = new Date(0)

    this.loading = false
    this.error = null
  }

  private needToUpdate(time: Date): boolean {
    return differenceInSeconds(Date.now(), time) > reloadPeriod
  }

  private setCategories(categories: TCategory[]) {
    categories.sort(sortBy('label'))
    this.categoriesList = categories
  }

  private addCategory(category: TCategory) {
    const index = this.categoriesList.findIndex((c) => c.categoryID === category.categoryID)
    if (index !== -1) {
      this.categoriesList[index] = category
    } else {
      this.categoriesList.push(category)
      this.categoriesList.sort(sortBy('label'))
    }
  }

  private delCategory(categoryID: number) {
    const index = this.categoriesList.findIndex((c) => c.categoryID === categoryID)
    if (index !== -1) {
      this.categoriesList.splice(index, 1)
    }
  }

  private setAttributes(attributes: TAttribute[]) {
    attributes.sort(sortBy('label'))
    this.attributesList = attributes
  }

  private addAttribute(attribute: TAttribute) {
    const index = this.attributesList.findIndex((a) => a.attributeID === attribute.attributeID)
    if (index !== -1) {
      this.attributesList[index] = attribute
    } else {
      this.attributesList.push(attribute)
      this.attributesList.sort(sortBy('label'))
    }
  }

  private delAttribute(attributeID: number) {
    const index = this.attributesList.findIndex((a) => a.attributeID === attributeID)
    if (index !== -1) {
      this.attributesList.splice(index, 1)
    }
  }

  private setDevicesIndex(index: TAttributeIndex) {
    // Object.entries(index).forEach(([deviceID, attributes]) => {
    //   this.devicesIndexRaw.set(deviceID, attributes)
    // })
    this.devicesIndexRaw = index
  }

  get categories(): TCategory[] {
    if (this.error != null) {
      return []
    }

    if (this.needToUpdate(this.catsLastFetch)) {
      this.fetchCategories(this.domainStore.core.current?.userID || '')
    }

    return this.categoriesList
  }

  get attributes(): TAttribute[] {
    if (this.error != null) {
      return []
    }

    if (this.needToUpdate(this.attrsLastFetch)) {
      this.fetchAttributes(this.domainStore.core.current?.userID || '')
    }

    return this.attributesList
  }

  get devicesIndex(): TAttributeIndex {
    if (this.error != null) {
      return {} // new Map<string, number[]>()
    }

    if (this.needToUpdate(this.dIndexLastFetch)) {
      this.fetchDevicesIndex(this.domainStore.core.current?.userID || '')
    }

    return this.devicesIndexRaw
  }

  getCategory(id: number): TCategory | null {
    const index = this.categories.findIndex((c) => c.categoryID === id)
    if (index !== -1) {
      return this.categories[index]
    }
    return null
  }

  // TODO private?
  async fetchCategories(userID: string) {
    this.loading = true

    try {
      const categories = await api.categories.list(userID)

      runInAction(() => {
        this.setCategories(categories)
        this.catsLastFetch = new Date()
        this.loading = false
      })
      return categories
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
    }
  }

  async createCategory(userID: string, label: string) {
    const newCat = await api.categories.create(userID, label)

    runInAction(() => {
      this.addCategory(newCat)
    })

    return newCat
  }

  async updateCategory(categoryID: number, label: string) {
    const newCat = await api.categories.update(categoryID, label)

    runInAction(() => {
      this.addCategory(newCat)
    })

    return newCat
  }

  async deleteCategory(categoryID: number) {
    await api.categories.delete(categoryID.toString())

    runInAction(() => {
      this.delCategory(categoryID)
    })

    return 'OK'
  }

  getAttribute(attributeID: number) {
    const index = this.attributes.findIndex((a) => a.attributeID === attributeID)
    if (index !== -1) {
      return this.attributes[index]
    }
    return null
  }

  async fetchAttributes(userID: string) {
    this.loading = true

    try {
      const attributes = await api.attributes.list(userID)

      runInAction(() => {
        this.setAttributes(attributes)
        this.attrsLastFetch = new Date()
        this.loading = false
      })
      return attributes
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
    }
  }

  async createAttribute(categoryID: number, label: string) {
    const newAttr = await api.attributes.create(categoryID, label)

    runInAction(() => {
      this.addAttribute(newAttr)
    })

    return newAttr
  }

  async updateAttribute(attributeID: number, label: string) {
    const attr = await api.attributes.update(attributeID, label)

    runInAction(() => {
      this.addAttribute(attr)
    })

    return attr
  }

  async deleteAttribute(attributeID: number) {
    await api.attributes.delete(attributeID)

    runInAction(() => {
      this.delAttribute(attributeID)
    })

    return 'OK'
  }

  async fetchDevicesIndex(userID: string) {
    this.loading = true

    try {
      const index = await api.attributes.index(userID, 'devices')

      runInAction(() => {
        this.setDevicesIndex(index)
        this.dIndexLastFetch = new Date()
        this.loading = false
      })
      return index
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
    }
  }

  async attachAttributeToDevice(devices: string[], attributeID: number) {
    this.loading = true

    try {
      const resp = await api.attributes.attach('devices', attributeID, devices.map((deviceID) => ({
        deviceID: deviceID,
        userID: this.domainStore.core.current?.userID || '',
      })))

      runInAction(() => {
        devices.forEach((deviceID) => {
          const currentDeviceIndex = this.devicesIndex[deviceID]
          if (currentDeviceIndex == null) {
            this.devicesIndex[deviceID] = [attributeID]
          } else {
            this.devicesIndex[deviceID].push(attributeID)
          }
        })
        this.loading = false
      })
      return resp
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
    }
  }

  async detachAttributefromDevice(devices: string[], attributeID: number) {
    this.loading = true

    try {
      const resp = await api.attributes.detach('devices', attributeID, devices.map((deviceID) => ({
        deviceID: deviceID,
        userID: this.domainStore.core.current?.userID || '',
      })))

      runInAction(() => {
        devices.forEach((deviceID) => {
          const currentDeviceIndex = this.devicesIndex[deviceID]
          if (currentDeviceIndex != null) {
            const idx = this.devicesIndex[deviceID].findIndex((aid) => aid === attributeID)
            if (idx !== -1) {
              this.devicesIndex[deviceID].splice(idx, 1)
            }
          }
        })
        this.loading = false
      })
      return resp
    } catch (err) {
      runInAction(() => {
        this.loading = false
        this.error = err
      })
    }
  }
}
