import { makeAutoObservable } from 'mobx';


import CoreStore from './coreStore';
import UsersStore from './usersStore';
import ServersStore from './serversStore';
import AccountsStore from './accountsStore';
import DevicesStore from './devicesStore';
import GroupsStore from './groupsStore';
import { getWindowHeight, getWindowWidth } from '../common';


// TODO make domain store internals by invoker software permissions
export class DomainStore {
    core: CoreStore
    users: UsersStore
    servers: ServersStore
    accounts: AccountsStore
    devices: DevicesStore
    groups: GroupsStore

    width: number
    height: number

    constructor() {
        makeAutoObservable(this)

        const core = new CoreStore(this)
        this.core = core
        this.users = new UsersStore()
        this.servers = new ServersStore(this)
        this.accounts = new AccountsStore(this)
        this.devices = new DevicesStore(this)
        this.groups = new GroupsStore(this)

        // TODO maybe UIStore?
        this.width = getWindowWidth()
        this.height = getWindowHeight()

        this.setWidth = this.setWidth.bind(this)
        this.setHeight = this.setHeight.bind(this)
    }

    setWidth(w: number) {
        this.width = w
    }

    setHeight(h: number) {
        this.height = h
    }
}

export function createStore(): DomainStore {
    return new DomainStore()
}
