export const features: Array<string> = [
    'informer',
    'dbloader',
    'liconfig',
    'tsconfig',
    'geozones',
    'reports',
    'plugins',
    'reportsVisibility',
    'tracks',
    'charts',
    'sharedLinks',
    'telematic',
    'tags',
]

export const SafeFeatures: Array<string> = [
    'informer',
    'dbloader',
    'liconfig',
    'tsconfig',
    'plugins',
    'reportsVisibility',
    'tracks',
    'charts',
]

export const StaffSafeFeatures: Array<string> = [
    'informer',
    'dbloader',
    'liconfig',
    'tsconfig',
    'geozones',
    'reports',
    'plugins',
    'reportsVisibility',
    'tracks',
    'charts',
]

export const StaffDisabledFeatures: Array<string> = [
    'telematic',
    'tags',
]

const featureFlags: Array<number> = [
    0x01,
    0x02,
    0x04,
    0x08,
    0x10,
    0x20,
    0x40,
    1 << 16,
    1 << (16 + 1),
    1 << (16 + 2),
    1 << (16 + 3),
    1 << (16 + 4),
    1 << (16 + 5),
]

export const Features = {
    // Flag allows Informer software
    // ual_li_allow: 0x00000001
    [features[0]]: featureFlags[0],

    // Flag allows Informer Database Loader
    // ual_db_loader_allow: 0x00000002
    [features[1]]: featureFlags[1],

    // Flag allows Informer configuration
    // ual_li_config: 0x00000004
    [features[2]]: featureFlags[2],

    // Flag allows terminals configuration
    // ual_ts_config: 0x00000008
    [features[3]]: featureFlags[3],

    // Flag allows geozones configuration
    // ual_cp_config: 0x00000010
    [features[4]]: featureFlags[4],

    // Flag allows reports configuration
    // ual_report_config: 0x00000020
    [features[5]]: featureFlags[5],

    // Flag allows Informer plugin configuration
    // ual_plugins_config: 0x00000040
    [features[6]]: featureFlags[6],

    // Flag allows to view and make reports
    [features[7]]: featureFlags[7],

    // Flag allows tracks
    [features[8]]: featureFlags[8],

    // Flag allows charts
    [features[9]]: featureFlags[9],

    // Flag allows shared links management
    [features[10]]: featureFlags[10],

    // Flag allows telematic servers and accounts configuration
    [features[11]]: featureFlags[11],

    // Flag allows Categories and Attributes management (users, devices, geozones)
    [features[12]]: featureFlags[12],
}

export function hasFeature(softwarePermissions: number, feature: string): boolean {
    const featureMask = Features[feature]
    if (featureMask == null) return false

    const accept = softwarePermissions & Features[feature]
    return accept !== 0
}

export function getAvailableFeatures(softwarePermissions: number): string[] {
    let aflags: string[] = []
    const len = featureFlags.length
    for (let i = 0; i < len; i++) {
        if ((softwarePermissions & featureFlags[i]) === 0) continue
        aflags.push(features[i])
    }
    return aflags
}

export function makePermissions(newFeatures: string[]): number {
    const fcount = newFeatures.length
    let permissions: number = 0
    let permission: number
    for (let i = 0; i < fcount; i++) {
        permission = Features[newFeatures[i]]
        if (permission == null) continue
        permissions |= permission
    }
    return permissions
}
