import React from 'react'
import { Checkbox, CheckboxGroup, Panel, PanelGroup } from 'rsuite'


import { TCategory, TAttribute } from '../../stores/interfaces'


interface Props {
  categories?: TCategory[] | null
  attributes?: TAttribute[] | null
  selected: number[]
  onChange?: (selected: number[]) => void
}

type TCheckedIndex = { [key: number]: number[] }

const reduceSelected = (selected: number[], cats?: TCategory[] | null, attrs?: TAttribute[] | null): TCheckedIndex => {
  if (cats == null || cats.length === 0) {
    return {}
  }

  if (attrs == null || attrs.length === 0) {
    return {}
  }

  const sIndex: TCheckedIndex = {}

  selected.forEach((aid) => {
    const a = attrs.find((attr) => attr.attributeID === aid)
    if (a == null) {
      return
    }

    const c = cats.find((cat) => cat.categoryID === a.categoryID)
    if (c == null) {
      return
    }

    if (sIndex[c.categoryID] == null) {
      sIndex[c.categoryID] = []
    }

    sIndex[c.categoryID].push(a.attributeID)
  })

  return sIndex
}

// FIXME another algorithm
const reduceChecked = (index: TCheckedIndex): number[] => {
  const mda = Object.entries(index).map(([, attrs]) => attrs)
  // return intersection(...mda)
  return ([] as number[]).concat(...mda)
}

export const TagFilter: React.FC<Props> = (props) => {
  const checked = reduceSelected(props.selected, props.categories, props.attributes)

  const onGroupChange = (cid: number) => (value: number[]) => {
    const nextChecked: TCheckedIndex = Object.assign({}, checked)
    if (value.length > 0) {
      nextChecked[cid] = value
    } else {
      delete nextChecked[cid]
    }

    if (props.onChange != null) {
      props.onChange(reduceChecked(nextChecked))
    }
  }

  const index: { [key: string]: TAttribute[] } = {}
  props.attributes != null && props.attributes.forEach((a) => {
    if (index[a.categoryID] == null) {
      index[a.categoryID] = []
    }
    index[a.categoryID].push(a)
  })

  if (props.categories == null) {
    return <div>
      Нет доступных категорий и аттрибутов
    </div>
  }

  return <div>
    <PanelGroup accordion>
      {props.categories.map((c) => {
        const attrs = index[c.categoryID]

        if (attrs == null || attrs.length === 0) {
          return null
        }

        const checkedAttrs = checked[c.categoryID] || []

        return <Panel key={c.categoryID} header={c.label} defaultExpanded={checkedAttrs.length > 0}>
          <CheckboxGroup
            value={checkedAttrs}
            onChange={onGroupChange(c.categoryID)}
          >
            {attrs.map((a) => {
              return <Checkbox key={`${a.categoryID}-${a.attributeID}`} value={a.attributeID}>
                {a.label}
              </Checkbox>
            })}
          </CheckboxGroup>
        </Panel>
      })}
    </PanelGroup>
  </div>
}
