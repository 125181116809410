import { makeAutoObservable, runInAction } from 'mobx';
import { differenceInSeconds } from 'date-fns';


import api from '../api/api';
import { apiCall, prod } from '../common';


import { DomainStore } from './store';
import { RPCError, TDevice } from './interfaces';


const entity = 'devices'
const reloadPeriod = 60

export default class DevicesStore {
    private originalListRaw: TDevice[]
    loading: boolean
    error: RPCError | null

    private filter: string
    private lastFetch: Date
    private domainStore: DomainStore

    constructor(domainStore: DomainStore) {
        this.originalListRaw = []
        this.filter = ''
        this.loading = false
        this.error = null
        this.lastFetch = new Date(0)
        this.domainStore = domainStore

        makeAutoObservable(this, {}, { autoBind: true })
    }

    dropError() {
        this.error = null
        this.loading = false
    }

    clear() {
        this.originalListRaw = []
        this.lastFetch = new Date(0)
        this.dropError()
    }

    setFilter(filter: string) {
        this.filter = filter.toUpperCase()
    }

    private setDevices(list: TDevice[]) {
        this.originalListRaw = list.map((d) => {
            d.endOfServiceDate = d.blackDate
            if (d.licenseBlackDate < d.blackDate) {
                d.endOfServiceDate = d.licenseBlackDate
            }
            return d
        })
    }

    getByID(id: string) {
        return this.originalList.find((d) => d.deviceID === id)
    }

    getLabel(id: string) {
        const d = this.originalListRaw.find((d) => d.deviceID === id)
        if (d == null) return '-'
        // TODO label from model + number or simply imei?
        return d.imei
    }

    private filterFunc(device: TDevice) {
        return device.imei.toUpperCase().indexOf(this.filter) !== -1 ||
            device.model.toUpperCase().indexOf(this.filter) !== -1 ||
            device.number.toUpperCase().indexOf(this.filter) !== -1
    }

    private needToUpdate(time: Date): boolean {
        return differenceInSeconds(Date.now(), time) > reloadPeriod
    }

    get originalList() {
        if (this.error != null) {
            return []
        }

        if (this.needToUpdate(this.lastFetch)) {
            this.fetchList(this.domainStore.core.current?.userID || '')
        }

        return this.originalListRaw
    }

    get list() {
        return this.originalList.filter(this.filterFunc)
    }

    async call(method: string, params?: any) {
        this.loading = true
        this.error = null

        try {
            const resp = await apiCall(method, params)

            if (resp.error) {
                throw resp.error
            }

            runInAction(() => {
                this.loading = false
            })

            return resp.result
        } catch (err) {
            runInAction(() => {
                this.loading = false
                this.error = err
            })

            !prod && console.info(method + ' call error:', err)
            throw err
        }
    }

    async count(userID: string, params?: any) {
        const count = await this.call(`${entity}.count`, { userID: userID, ...params })
        return count
    }

    async fetchList(userID: string) {
        const resp = await this.call(`${entity}.list`, { userID: userID })

        runInAction(() => {
            this.lastFetch = new Date()
            this.setDevices(resp)
        })
        return resp
    }

    async fetchAndGet(userID: string, deviceID: string): Promise<TDevice | undefined> {
        let device = this.list.find((d) => d.deviceID === deviceID)

        if (device != null) {
            return device
        }

        const resp = await this.call(`${entity}.list`, { userID: userID })

        runInAction(() => {
            this.setDevices(resp)
        })

        device = resp.find((d: TDevice) => d.deviceID === deviceID)

        return device
    }

    async refreshList() {
        const userID = this.domainStore.core.current?.userID || ''
        await api.devices.refresh(userID)
        await this.fetchList(userID)
    }
}
