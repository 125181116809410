const root = '/id'

const idURL = process.env.REACT_APP_ID_URL
const mapsURL = process.env.REACT_APP_MAPS_URL

export default {
    // Private
    // Top-level
    // home: root + '/',
    // main: root + '/u',

    idPath: idURL,
    mapsPath: mapsURL,

    main: root,
    dashboard: '/dashboard',
    reports: '/reports',

    // Dashboard internals
    servers: '/servers',
    accounts: '/accounts',
    clients: '/clients',
    staff: '/staff',
    devices: '/devices',
    groups: '/groups',

    // Preports internals
    activity: '/activity',

    // User-related
    settings: '/settings',
    logout: root + '/logout',

    // Public
    login: root + '/login',
    restore: root + '/restore',
};
