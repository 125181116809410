export const equals = (arr1: any[], arr2: any[]) => {
  if (arr1 == null || arr2 == null) {
    return false
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  const array1 = arr1.slice()
  array1.sort()

  const array2 = arr2.slice()
  array2.sort()

  // if (array1 !== array2) {
  //   return false
  // }

  return array1.length === array2.length &&
    array1.every((value: any, index: number) => value === array2[index])
}

export const includes = (array1: any[], array2: any[]) => {
  for (let i = 0; i < array2.length; i++) {
    if (!array1.includes(array2[i])) {
      return false
    }
  }
  return true
}
