import React from 'react'
import { Link, withRouter } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Breadcrumb } from 'rsuite';

import { useBreadcrumbs } from '../../hooks';
import { useStore } from '../../stores';

import styles from './Breadcrumbs.module.css'


const NavLink = props => <Breadcrumb.Item componentClass={Link} {...props} />;


const Breadcrumbs = (props) => {
  const store = useStore()
  const bc = useBreadcrumbs(props.routes)

  return <div className={styles.breadcrumbs}>
    <Breadcrumb separator={'>'} maxItems={4}>
      {/* {bc.length === 1 && <NavLink to={props.fallback.url}>{props.fallback.breadcrumb}</NavLink>} */}

      {bc.map(({ match, breadcrumb }, index) => {
        let linkName = breadcrumb
        if (typeof linkName === 'function') {
          linkName = linkName(match.params.id, store)
        }
        return <NavLink active={bc.length - 1 === index} key={`nav-${index}`} to={match.url || ''}>{linkName}</NavLink>
      })}
    </Breadcrumb>
  </div >
}

Breadcrumbs.displayName = 'Breadcrumbs'

export default withRouter(observer(Breadcrumbs))
