import { differenceInSeconds } from 'date-fns';
import { makeAutoObservable, runInAction } from 'mobx';


import { apiCall, hasFeature, prod } from '../common';
import { RPCError, TServer } from './interfaces';
import { DomainStore } from './store';


const entity = 'servers'
const reloadPeriod = 60


const filterFunc = (filter: string) => {
    return (server: TServer) =>
        server.title.toLowerCase().indexOf(filter) !== -1 ||
        server.host.toLowerCase().indexOf(filter) !== -1
}

export default class ServersStore {
    private originalList: TServer[]
    filter: string
    loading: boolean
    error: RPCError | null
    private lastFetch: Date
    private domainStore: DomainStore

    constructor(domainStore: DomainStore) {
        this.originalList = []
        this.filter = ''
        this.loading = false
        this.error = null
        this.lastFetch = new Date(0)
        this.domainStore = domainStore

        makeAutoObservable(this, {}, { autoBind: true })
    }

    dropError() {
        this.error = null
        this.loading = false
    }

    clear() {
        this.originalList = []

        this.dropError()
    }

    getByID(id: string) {
        return this.originalList.find((e) => e.serverID === id)
    }

    getLabel(id: string, newLabel: string) {
        if (id === 'new') return newLabel
        const e = this.originalList.find((e) => e.serverID === id)
        if (e == null) return '-'
        return e.title
    }

    setFilter(filter: string) {
        this.filter = filter
    }

    get list() {
        if (this.error != null) {
            return this.originalList
        }

        if (!hasFeature(this.domainStore.core.current?.softwarePermissions || 0, 'telematic')) {
            return this.originalList
        }

        if (differenceInSeconds(Date.now(), this.lastFetch) > reloadPeriod) {
            this.fetchList(this.domainStore.core.current?.userID || '')
        }

        return this.originalList.filter(filterFunc(this.filter))
    }

    setServers(servers: TServer[]) {
        if (servers == null) return
        this.originalList = servers
    }

    addServer(server: TServer) {
        const index = this.originalList.findIndex((e) => e.serverID === server.serverID)
        if (index === -1) {
            this.originalList.push(server)
        } else {
            this.originalList[index] = server
        }
    }

    async call(method: string, params?: any) {
        runInAction(() => {
            this.loading = true
            this.error = null
        })

        try {
            const resp = await apiCall(method, params)

            if (resp.error) {
                throw resp.error
            }

            runInAction(() => {
                this.loading = false
            })

            return resp.result
        } catch (err) {
            runInAction(() => {
                this.loading = false
                this.error = err
            })

            !prod && console.info(method + ' call error:', err)
            throw err
        }
    }

    async count(userID: string, params?: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const count = await this.call(`${entity}.count`, { userID: userID, ...params })
                resolve(count)
            } catch (err) {
                reject(err)
            }
        })
    }

    async fetchList(userID: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const servers = await this.call(`${entity}.list`, { userID: userID })

                runInAction(() => {
                    this.lastFetch = new Date()
                    this.setServers(servers)
                })
                resolve(servers)
            } catch (err) {
                reject(err)
            }
        })
    }

    async upsert(server: TServer) {
        let method = 'create'
        if (server.serverID && server.serverID.length > 0) {
            method = 'update'
        }

        const resp = await this.call(`${entity}.${method}`, server)

        runInAction(() => {
            this.addServer(resp)
        })
        return resp
    }

    async remove(serverID: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await this.call(`${entity}.delete`, { serverID: serverID })

                runInAction(() => {
                    const index = this.originalList.findIndex((s) => s.serverID === serverID)
                    if (index !== -1) {
                        this.originalList.splice(index, 1)
                        this.originalList = [...this.originalList]
                    }
                })
                resolve(resp)
            } catch (err) {
                reject(err)
            }
        })
    }

    async check(serverID: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const resp = await this.call(`${entity}.check`, { serverID })

                runInAction(() => {
                    this.addServer(resp)
                })
                resolve(resp)
            } catch (err) {
                reject(err)
            }
        })
    }
}
