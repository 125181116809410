import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, useLocation, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import { Helmet } from "react-helmet";


import { slugs } from '../common';
import { Dimmer, AppHeader, RouteWithSubRoutes, SideNavigation } from '../components';
import { useStore } from '../stores';


const RootUser = lazy(() => import('../pages/Users/RootUser'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Reports = lazy(() => import('../pages/Reports'));
const Servers = lazy(() => import('../pages/Servers'));
const Accounts = lazy(() => import('../pages/Accounts'));
const ServerView = lazy(() => import('../pages/Servers/ServerView'));
const Clients = lazy(() => import('../pages/Users'));
const Staff = lazy(() => import('../pages/Users/Staff'));
const EditStaff = lazy(() => import('../pages/Users/EditStaff'));
const UserView = lazy(() => import('../pages/Users/UserView.tsx'));
// const UserDelegation = lazy(() => import('../pages/Delegation/UserDelegation.tsx'));
const Devices = lazy(() => import('../pages/Devices'));
const DeviceView = lazy(() => import('../pages/Devices/DeviceView'));
const Groups = lazy(() => import('../pages/Groups/Groups.tsx'));
const Settings = lazy(() => import('../pages/Settings'));
const Logout = lazy(() => import('../pages/Logout'));


const userRoot = '/:userID'

export const routes = [
    {
        key: 'logout',
        path: slugs.logout,
        component: Logout,
        exact: true,
    },
    {
        key: 'userID',
        path: slugs.main + userRoot,
        component: RootUser,
        routes: [
            {
                key: 'dashboard',
                path: slugs.main + userRoot + slugs.dashboard,
                component: Dashboard,
            },
            {
                key: 'servers',
                path: slugs.main + userRoot + slugs.servers,
                component: Servers,
                feature: 'telematic',
                routes: [
                    {
                        path: slugs.main + userRoot + slugs.servers + '/:id',
                        component: ServerView,
                    },
                ],
            },
            {
                key: 'accounts',
                path: slugs.main + userRoot + slugs.accounts,
                component: Accounts,
                feature: 'telematic',
            },
            {
                key: 'devices',
                path: slugs.main + userRoot + slugs.devices,
                component: Devices,
                routes: [
                    {
                        path: slugs.main + userRoot + slugs.devices + '/:id',
                        component: DeviceView,
                    },
                ],
            },
            {
                key: 'staff',
                path: slugs.main + userRoot + slugs.staff,
                component: Staff,
                breadcrumb: 'Сотрудники',
                routes: [
                    {
                        path: slugs.main + userRoot + slugs.staff + '/:id',
                        component: EditStaff,
                        // routes: [{
                        //     path: slugs.main + userRoot + slugs.staff + '/:id/delegation',
                        //     component: UserDelegation,
                        // }],
                    },
                ],
            },
            {
                key: 'clients',
                path: slugs.main + userRoot + slugs.clients,
                component: Clients,
                breadcrumb: 'Клиенты',
                routes: [
                    {
                        path: slugs.main + userRoot + slugs.clients + '/:id',
                        exact: true,
                        component: UserView,
                    },
                ],
            },
            {
                key: 'tags',
                path: slugs.main + userRoot + slugs.groups,
                component: Groups,
                feature: 'tags',
            },
            {
                key: 'reports',
                path: slugs.main + userRoot + slugs.reports,
                component: Reports,
                feature: 'reportsVisibility',
                // exact: true,
            },
            {
                key: 'settings',
                path: slugs.main + userRoot + slugs.settings,
                component: Settings,
                exact: true,
            },
        ],
    },
]

const PrivateSection = ({ strings, match }) => {
    const store = useStore()
    const { pathname } = useLocation()

    const target = [
        slugs.login,
        slugs.logout,
        slugs.restore,
        '/',
        '/id',
        null,
    ].includes(pathname) ? '' : window.location.href //pathname

    let loginSlug = slugs.login
    if (target.length > 0) {
        // TODO strip slash?
        loginSlug += `?redirect=${encodeURIComponent(target)}`
    }

    if (store.core.invoker == null) return <Redirect to={loginSlug} />

    return <>
        <Helmet>
            <title>Locarus ID</title>
        </Helmet>

        <AppHeader />

        <div className='main'>
            {/* <div className='mainWrapper'> */}

            <SideNavigation />

            <div className='content'>
                {/* FIXME {store.core.current?.userID !== store.core.invoker.userID && <div style={{ height: '54px' }}></div>} */}

                <Suspense fallback={<Dimmer />}>
                    <Switch>
                        {routes.map((route, i) => {
                            // if (route.key === 'reports' && !allowReports) {
                            //     return null
                            // }

                            // if (route.key === 'staff' && !store.core.current.isOrg) {
                            //     return null
                            // }

                            // if (route.key === 'clients' && !store.core.current.allowNestedUsers) {
                            //     return null
                            // }

                            // if (route.feature && !hasFeature(store.core.current.softwarePermissions, route.feature)) {
                            //     return null
                            // }

                            return <RouteWithSubRoutes key={'route-' + route.key} {...route} />
                        })}

                        <Redirect to={slugs.main + '/0'} />
                    </Switch>
                </Suspense>
            </div>
            {/* </div> */}
        </div>
    </>
}

export default withRouter(observer(PrivateSection));
