export const browserStorage = {
  localStoreSupport: function (): boolean {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  },
  /**
   * 
   * @param name 
   * @param value 
   * @param expiration in seconds
   */
  set: function (name: string, value: any, expiration?: number) {
    let expires = ''

    if (expiration) {
      const date = new Date();
      date.setTime(date.getTime() + (expiration * 1000));
      expires = "; expires=" + date.toUTCString();
    }

    if (this.localStoreSupport()) {
      localStorage.setItem(name, value);
    }
    else {
      document.cookie = name + "=" + value + expires + "; path=/";
    }
  },
  get: function (name: string): string | null {
    if (this.localStoreSupport()) {
      return localStorage.getItem(name);
    }
    else {
      var nameEQ = name + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
  },
  del: function (name: string) {
    if (this.localStoreSupport()) {
      localStorage.removeItem(name);
    }
    else {
      this.set(name, "", -1);
    }
  }
}