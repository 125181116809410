import ky from 'ky';


import { prod, slugs, errors } from '../common'


import { TDevice, RPCError, TCategory, TAttribute, TAttributeIndex, TAccount } from '../stores/interfaces';


interface RPCResponse {
  readonly jsonrpc: '2.0',
  readonly id?: string,
  readonly result?: unknown,
  readonly error?: RPCError,
}


const openMethods: string[] = [
  'signin',
  'restore.request',
  'restore.check',
  'restore.complete',
  'signout', // TODO ?
]

const generateRequestID = (): string => {
  return Math.floor(Math.random() * 1000000).toString()
}

export const call = async (method: string, params?: unknown) => {
  const requestOptions = {
    method: 'post',
    json: {
      jsonrpc: '2.0',
      id: generateRequestID(),
      method: method,
      ...(params != null && { params: params }),
    }
  }

  try {
    const resp: RPCResponse = await ky('/api/', requestOptions).json()

    if (resp.error) {
      throw resp.error
    }

    !prod && console.info(method + ' call result:', resp.result)
    return resp.result
  } catch (err) {
    if (err.code === errors.unauthorized && !openMethods.includes(method)) {
      window.location.href = slugs.idPath + slugs.login + '?redirect=' + encodeURIComponent(window.location.href)
    }

    !prod && console.info(method + ' call error:', err)
    throw err
  }
}

interface IChangePassword {
  userID: string
  currentPassword?: string
  newPassword: string
}

type AttributeEntity = 'users' | 'devices' | 'geozones'

interface DeviceAttach {
  userID: string
  deviceID: string
}

type AttributeAttach = string | DeviceAttach

export default {
  auth: {
    signin: (userID: string) => call('signin', { userID: userID }),
    // signout
  },

  restore: {
    request: (email: string) => call('restore.request', { email: email }),
    check: (token: string) => call('restore.check', { token: token }),
    complete: (token: string, password: string) => call('restore.complete', { token: token, password: password }),
  },

  // users.current
  // users.get
  // users.list
  // users.count
  // users.create
  // users.update
  // users.delete
  // users.search
  users: {
    changePassword: (data: IChangePassword) => call('users.changePassword', data),
    search: (userID: string, search: string) => call('users.search', { userID: userID, text: search }),
  },

  // usersInfo.update

  servers: {
    // servers.list
    // servers.count
    // servers.create
    // servers.update
    // servers.delete
    check: (serverID: string) => call('servers.check', { serverID: serverID }),
  },

  accounts: {
    // accounts.list
    // accounts.count
    // accounts.create
    // accounts.update
    // accounts.delete
    // accounts.refresh
    check: (accountID: string) => call('accounts.check', { accountID: accountID }),
    create: (account: TAccount): Promise<TAccount> => call('accounts.create', account) as Promise<TAccount>,
    update: (account: TAccount): Promise<TAccount> => call('accounts.update', account) as Promise<TAccount>,
  },

  // devices.list
  // devices.count
  // devices.refresh
  // devices.attachGeozoneGroup
  // devices.detachGeozoneGroup
  // devices.getGeozoneGroupsLinks
  devices: {
    list: (userID: string, foreignOnly: boolean = false): Promise<TDevice[]> =>
      call('devices.list', { userID: userID, foreignOnly: foreignOnly }) as Promise<TDevice[]>,
    refresh: (userID: string) => call('devices.refresh', { userID: userID }),
  },

  devicesDelegation: {
    // devicesDelegation.create
    // devicesDelegation.delete

    // TODO permissions param
    set: (userID: string, devices: string[]) => call('devicesDelegation.set', { userID: userID, devices: devices }),
  },

  reports: {
    activity: (params: any) => call('reports.activeDevices', params),
  },

  // sharedLinks.list
  // sharedLinks.count
  // sharedLinks.delete

  // geozones.list
  // geozones.count
  // geozones.delete
  // geozones.create
  // geozones.update

  // geozoneGroups.list
  // geozoneGroups.count
  // geozoneGroups.delete
  // geozoneGroups.create
  // geozoneGroups.update
  // geozoneGroups.setDefault
  // geozoneGroups.resetDefault
  // geozoneGroups.getDefault
  // geozoneGroups.addGeozones
  // geozoneGroups.delGeozones

  categories: {
    list: (userID: string): Promise<TCategory[]> =>
      call('categories.list', { userID: userID }) as Promise<TCategory[]>,

    count: (userID: string) => call('categories.count', { userID: userID }),

    delete: (categoryID: string) => call('categories.delete', { categoryID: +categoryID }),

    create: (userID: string, label: string): Promise<TCategory> =>
      call('categories.create', { userID: userID, label: label }) as Promise<TCategory>,

    update: (categoryID: number, label: string): Promise<TCategory> =>
      call('categories.update', { categoryID: categoryID, label: label }) as Promise<TCategory>,
  },

  attributes: {
    list: (userID: string): Promise<TAttribute[]> =>
      call('attributes.list', { userID: userID }) as Promise<TAttribute[]>,

    count: (userID: string) => call('attributes.count', { userID: userID }),
    delete: (attributeID: number) => call('attributes.delete', { attributeID: attributeID }),

    create: (categoryID: number, label: string): Promise<TAttribute> =>
      call('attributes.create', { categoryID: categoryID, label: label }) as Promise<TAttribute>,

    update: (attributeID: number, label: string): Promise<TAttribute> =>
      call('attributes.update', { attributeID: attributeID, label: label }) as Promise<TAttribute>,

    index: (userID: string, entity: AttributeEntity): Promise<TAttributeIndex> =>
      call('attributes.index', { userID: userID, entity: entity }) as Promise<TAttributeIndex>,

    attach: (entity: AttributeEntity, attributeID: number, entityID: AttributeAttach[]) => call('attributes.attach', {
      entity: entity,
      attributeID: attributeID,
      entityID: entityID,
    }),
    detach: (entity: AttributeEntity, attributeID: number, entityID: AttributeAttach[]) => call('attributes.detach', {
      entity: entity,
      attributeID: attributeID,
      entityID: entityID,
    }),
  },

  // files.list
  // files.get
  // files.set
  // files.delete

  // reports.activeDevices
}

// TODO ignore bad files
// json:"ignoreBadFiles"
// accounts.create
// dd.add
// dd.set