import React from 'react';
import { Link } from 'react-router-dom';


const bold = { fontWeight: '600' };


export const UserIdentity = ({ user, styles, link, className }) => {
  if (user == null) return null;

  const label = user.label || user.firstName + '' + user.lastName

  if (link) {
    return <div style={styles} className={className}>
      <Link to={link} style={bold} title={`На панель управления пользователя ${label}`}>
        {label}
      </Link>

      <div>{user.label || user.login || user.email}</div>
    </div>
  }

  return <div style={styles} className={className}>
    <div>{user.label || user.login || user.email}</div>
  </div>
}
