import { makeAutoObservable, runInAction } from 'mobx';
import { differenceInSeconds } from 'date-fns';


import { apiCall, hasFeature, prod } from '../common';
import { RPCError, TAccount } from './interfaces';
import { DomainStore } from './store';
import api from '../api/api';


const entity = 'accounts'
const reloadPeriod = 60

const filterFunc = (filter: string) => {
    return (account: TAccount) =>
        account.title.toLowerCase().indexOf(filter) !== -1 ||
        account.login.toLowerCase().indexOf(filter) !== -1
}

export default class AccountsStore {
    private originalList: TAccount[]
    private lastFetch: Date
    private domainStore: DomainStore

    filter: string
    loading: boolean
    error: RPCError | null

    constructor(domainStore: DomainStore) {
        this.originalList = []
        this.lastFetch = new Date(0)
        this.domainStore = domainStore

        this.filter = ''
        this.loading = false
        this.error = null

        makeAutoObservable(this, {}, { autoBind: true })
    }

    private needToUpdate(time: Date): boolean {
        return differenceInSeconds(Date.now(), time) > reloadPeriod
    }


    private dropError() {
        this.error = null
        this.loading = false
    }

    clear() {
        this.originalList = []
        this.lastFetch = new Date(0)
        this.filter = ''
        this.dropError()
    }

    getByID(id: string) {
        return this.originalList.find((e) => e.accountID === id)
    }

    getLabel(id: string) {
        const e = this.originalList.find((e) => e.accountID === id)
        if (e == null) return '-'
        return e.accountID
    }

    setFilter(filter: string) {
        this.filter = filter
    }

    get list() {
        if (this.error != null) {
            return []
        }

        if (!hasFeature(this.domainStore.core.current?.softwarePermissions || 0, 'telematic')) {
            return []
        }

        if (this.needToUpdate(this.lastFetch)) {
            this.fetchList(this.domainStore.core.current?.userID || '')
        }

        return this.originalList.filter(filterFunc(this.filter))
    }

    private setAccounts(list: TAccount[]) {
        if (list == null) return
        this.originalList = list
    }

    addAccount(account: TAccount) {
        const index = this.originalList.findIndex((a) => a.accountID === account.accountID)
        if (index === -1) {
            this.originalList.push(account)
        } else {
            this.originalList[index] = account
        }
    }

    private async call(method: string, params?: any) {
        this.loading = true
        this.error = null
        try {
            const resp = await apiCall(method, params)

            if (resp.error) {
                throw resp.error
            }

            runInAction(() => {
                this.loading = false
            })

            return resp.result
        } catch (err) {
            runInAction(() => {
                this.loading = false
                this.error = err

                // TODO unauthorized reaction
                // if (err.code === errors.unauthorized) {
                //     this.dropStore()
                // }
            })

            !prod && console.info(method + ' exception', err)
            throw err
        }
    }

    async count(userID: string, params?: any) {
        return new Promise(async (resolve, reject) => {
            try {
                const count = await this.call(`${entity}.count`, { userID: userID, ...params })
                resolve(count)
            } catch (err) {
                reject(err)
            }
        })
    }

    async fetchList(userID: string) {
        const accounts = await this.call(`${entity}.list`, { userID: userID })

        runInAction(() => {
            this.setAccounts(accounts)
            this.lastFetch = new Date()
        })

        return accounts
    }

    async upsert(a: TAccount) {
        const action = a.accountID ? 'update' : 'create'
        const account = await api.accounts[action](a)  // await this.call(`${entity}.${action}`, a)

        runInAction(() => {
            this.addAccount(account)
        })

        return account
    }

    async remove(accountID: string) {
        const accounts = await this.call(`${entity}.delete`, { accountID })

        runInAction(() => {
            const index = this.originalList.findIndex((a) => a.accountID === accountID)
            if (index !== -1) {
                this.originalList.splice(index, 1)
                this.originalList = [...this.originalList]
            }
        })

        return accounts
    }

    async refresh(accountID: string) {
        await this.call(`${entity}.refresh`, { accountID })
    }

    async check(accountID: string) {
        const account = await this.call(`${entity}.check`, { accountID })

        runInAction(() => {
            this.addAccount(account)
        })

        return account
    }
}
