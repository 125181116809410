import React from 'react'
import { Checkbox, CheckboxGroup, Panel } from 'rsuite'


import { TAccount } from '../../stores/interfaces'


interface Props {
  accounts: TAccount[] | null
  selected?: string[]
  onChange?: (accounts: string[]) => void
}


export const AccountsFilter = React.memo<Props>((props) => {
  const onChange = (value: string[]) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  if (props.accounts == null) {
    return <div>
      Нет доступных категорий и аттрибутов
    </div>
  }

  return <Panel header={'Аккаунты'} collapsible defaultExpanded>
    <CheckboxGroup onChange={onChange} value={props.selected}>
      {props.accounts.map((a) => {
        return <Checkbox key={a.accountID} value={a.accountID}>
          {a.title}
        </Checkbox>
      })}
    </CheckboxGroup>
  </Panel>
})
