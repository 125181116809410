import React from 'react';
import { Divider } from 'rsuite';


interface EmptySearchProps {
  text: string,
}

const dividerStyles = { marginTop: '19px', marginBottom: '13px' }

const EmptySearch: React.FC<EmptySearchProps> = ({ text }) => {
  return <>
    <Divider style={dividerStyles} />
    <div>{text}</div>
  </>
}

export default EmptySearch;
