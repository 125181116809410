import React from 'react';
// import CustomScroller from 'react-custom-scroller';
import CustomScroller from 'react-custom-scroll';


import styles from './ScrollWrapper.module.css';


const ScrollWrapper = ({ children }) => {
  // return <CustomScroller
  //   flex='1'
  // // className={styles.scroller}
  // // innerClassName={styles.gridWrapper}
  // >
  //   {children}
  // </CustomScroller>

  return <div className={styles.customScroller}>
    {children}
  </div>
}

export default ScrollWrapper;

export const SimpleScroller = ({ children }) => (
  <CustomScroller>{children}</CustomScroller>
)
