import React from 'react'
import { Icon, Popover as RPopover, Whisper } from 'rsuite'


interface PopoverProps {
  label: string
}


const styles = {
  cursor: 'pointer',
}

const Popover: React.FC<PopoverProps> = (props) => {
  const speaker = <RPopover>
    <div>
      {props.children}
    </div>
  </RPopover>

  return <Whisper placement='bottom' trigger='click' speaker={speaker}>
    <div style={styles}>
      <span>{props.label}</span> <Icon icon='angle-down' />
    </div>
  </Whisper>
}

export default Popover
