import { TDevice } from "../stores/interfaces";
import { makeDeviceLabel } from "./labels";


export enum SortType {
  ASC,
  DSC,
};

interface TSortable { [index: string]: any }


export const sortDevices = (sortType: SortType) => (a: TDevice, b: TDevice) => {
  const rightShifter = sortType === SortType.ASC ? -1 : 1
  const leftShifter = sortType === SortType.ASC ? 1 : -1

  // const ai = a.imei.toUpperCase()
  // const bi = b.imei.toUpperCase()
  // if (ai < bi) return rightShifter
  // if (ai > bi) return leftShifter

  // const an = a.number.toUpperCase()
  // const bn = b.number.toUpperCase()
  // if (an < bn) return rightShifter
  // if (an > bn) return leftShifter

  // const am = a.model.toUpperCase()
  // const bm = b.model.toUpperCase()
  // if (am < bm) return rightShifter
  // if (am > bm) return leftShifter
  // return 0

  const al = makeDeviceLabel(a)
  const bl = makeDeviceLabel(b)
  if (al < bl) return rightShifter
  if (al > bl) return leftShifter
  return 0
};

export const sortBy = (field: string = '', asc: boolean = true) => (a?: TSortable | string, b?: TSortable | string) => {
  if (a == null || b == null) {
    return 0
  }

  const afr = typeof a === 'string' ? a : a[field]
  const bfr = typeof b === 'string' ? b : b[field]

  if (afr == null && bfr == null) {
    return 0
  }

  if (afr == null) {
    return asc ? -1 : 1
  }

  if (bfr == null) {
    return asc ? 1 : -1
  }

  const af = typeof afr === 'string' ? afr.toUpperCase() : afr
  const bf = typeof bfr === 'string' ? bfr.toUpperCase() : bfr

  // FIXME is it right decision?
  if (afr.length === 0) {
    return asc ? 1 : -1
  }

  if (bfr.length === 0) {
    return asc ? -1 : 1
  }

  if (af < bf) return asc ? -1 : 1
  if (af > bf) return asc ? 1 : -1
  return 0
}
