import React from 'react'
import PropTypes from 'prop-types'
import { Panel } from 'rsuite';


import styles from './DashboardTile.module.css'


const DashboardTile = ({ title, children, image }) => {
    // shaded
    return <Panel bordered bodyFill className={styles.dashboardTile}>
        <div className={styles.text}>
            {title && <h4>{title}</h4>}
            {children}
        </div>
    </Panel>
}

DashboardTile.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
}

DashboardTile.defaultProps = {
    title: '',
    children: [],
}

export default DashboardTile

export const DashboardCounter = ({ count }) => {
    return <div className={styles.tileCounter}>{count}</div>
}

DashboardCounter.propTypes = {
    count: PropTypes.number,
}

DashboardCounter.defaultProps = {
    count: 0,
}
