import React from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from 'rsuite';


import { useStore } from '../stores';
import { getErrorMessage } from '../common';
import ErrorBlock from './ErrorBlock';


interface ServersDisplayProps {
  serverID: string
};


const ServersDisplay: React.FC<ServersDisplayProps> = (props) => {
  const store = useStore()

  let error: string | null = null
  if (error) {
    error = getErrorMessage(store.servers.error, 'Ошибка при загрузке списка серверов')
  }

  const server = store.servers.list.find((s) => s.serverID === props.serverID)

  return <div>
    {store.servers.loading && <Loader />}

    {error && <ErrorBlock
      title={'Не удалось загрузить список серверов'}
      error={error}
      actionCaption={'Попробовать снова'}
      action={() => store.servers.fetchList(store.core.current?.userID || '')}
    />}

    {!store.servers.loading && !error && server != null && <div>
      {server.title}
    </div>}
  </div>
};

export default observer(ServersDisplay);
