import React from 'react';
import { NavLink, withRouter } from 'react-router-dom'
import { observer } from 'mobx-react-lite';
import { Divider, Drawer, Icon, IconButton } from 'rsuite';


import { hasFeature, slugs } from '../../common';
import { useStore } from '../../stores';
import { UserIdentity } from '../UserIdentity';
import { version } from '../../version';


import styles from './SideNavigation.module.css'


const SideNavigation = (props) => {
  const store = useStore()

  if (store.core.current == null) return null

  const id = store.core.current.userID === store.core.invoker.userID ? '0' : store.core.current.userID
  const currentPath = slugs.main + '/' + id
  const entries = [
    {
      key: 'dashboard',
      path: currentPath + slugs.dashboard,
      title: 'Панель управления',
      icon: 'dashboard',
    },
    {
      key: 'firstSection',
      divide: true,
    },
    {
      key: 'servers',
      path: currentPath + slugs.servers,
      title: 'Серверы',
      icon: 'server',
      feature: 'telematic',
    },
    {
      key: 'accounts',
      path: currentPath + slugs.accounts,
      title: 'Аккаунты',
      icon: 'profile',
      feature: 'telematic',
    },
    {
      key: 'devices',
      path: currentPath + slugs.devices,
      title: 'Устройства',
      icon: 'car',
    },
    {
      key: 'staff',
      path: currentPath + slugs.staff,
      title: 'Сотрудники',
      icon: 'people-group',
    },
    {
      key: 'clients',
      path: currentPath + slugs.clients,
      title: 'Клиенты',
      icon: 'sitemap',

    },
    {
      key: 'lastSection',
      divide: true,
    },
    {
      key: 'tags',
      path: currentPath + slugs.groups,
      title: 'Группы',
      icon: 'object-group',
      feature: 'tags',
    },
    {
      key: 'reports',
      path: currentPath + slugs.reports,
      title: 'Отчеты',
      icon: 'bar-chart',
      feature: 'reportsVisibility',
    },
  ]

  const mobile = store.width <= 920
  const hideMenu = () => { store.core.setSideMenuState(false) }

  const menu = entries
    .filter((e) => {
      if (e.key === 'staff' && !store.core.current.isOrg) {
        return null
      }

      if (e.key === 'clients' && !store.core.current.allowNestedUsers) {
        return null
      }

      if (e.feature && !hasFeature(store.core.current.softwarePermissions, e.feature)) {
        return null
      }
      return e
    }).map((e, key, all) => {
      if (e.divide && all.length - 1 === key) {
        return null
      }

      if (e.divide) {
        return <div key={e.key} className={styles.navLinks}>
          <Divider style={{ marginTop: '12px', marginBottom: '8px' }} />
        </div>
      }

      return <div key={e.key} className={styles.navLinks}>
        <NavLink to={e.path} className={styles.link} activeClassName={styles.activeLink} onClick={mobile ? hideMenu : null}>
          <Icon icon={e.icon} /> {e.title}
        </NavLink>
      </div>
    })

  const userID = store.core.current.ownerID === store.core.invoker.userID ? 0 : store.core.current.ownerID
  const parentPath = `${slugs.main}/${userID}${slugs.clients}`
  const isInvoker = store.core.current.userID === store.core.invoker.userID

  if (mobile) {
    return <Drawer
      placement='left'
      size={'xs'}
      full={store.width <= 920}
      show={store.core.isSideMenuOpen}
      onHide={hideMenu}
    >
      <Drawer.Header>
        <Drawer.Title>Меню</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body>
        <nav className={styles.sideNav}>
          {menu}
        </nav>
      </Drawer.Body>
    </Drawer>
  }

  // FIXME go-to-parent navigation is a dirty hack, rewrite if possible
  return <div className={styles.sideNavBlock}>
    <nav className={styles.sideNav}>
      {!isInvoker && store.width > 920 && <div className={styles.clientSection}>

        <a href={parentPath}>
          <IconButton appearance='ghost' icon={<Icon icon='level-up' />} className={styles.longButton}>
            Наверх
          </IconButton>
        </a>

        <UserIdentity user={store.core.current} className={styles.clientCaption} />
      </div>}

      {menu}
    </nav>

    <div className={styles.versionContainer}>
      <span>Версия {version}</span>
    </div>
  </div>
}

export default withRouter(observer(SideNavigation))
