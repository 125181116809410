import React from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Icon } from 'rsuite'


interface CreationTileCardProps {
  className?: string
  href: string
  caption?: string
}


const CreationTileCard: React.FC<CreationTileCardProps> = ({ href, caption, className }) => {
  const additionalClasses = className != null && className.length > 0 ? ' ' + className : ''

  return <Link to={href} className={`tileCard creationTile${additionalClasses}`}>
    <IconButton
      appearance='ghost'
      circle
      icon={<Icon icon='plus' />}
      className='creationButton'
    />
    {caption != null && caption.length > 0 && <span className='creationTileCaption'>{caption}</span>}
  </Link>
}

export default CreationTileCard
