// const errorCodes = {
//   [-32600]: 'invalidRequest',
//   [-32601]: 'methodNotFound',
//   [-32602]: 'invalidArgs',
//   [-32603]: 'internalError',
//   [-32700]: 'parseError',

//   [-32001]: 'unauthorized',
//   [-32002]: 'accessDenied',
//   [-32003]: 'notFound',
//   [-32004]: 'telematic',
//   [-32005]: 'server',
//   [-32006]: 'account',
// }

// TODO servers and accounts statuses
// // ServerErrCodeUnknown is unknown error
// ServerErrCodeUnknown = 1
// // ServerErrCodeWrongVersion is wrong telematic version
// ServerErrCodeWrongVersion = 2

// // ServerErrCodeInvalidHTTPPort is bad http port
// ServerErrCodeInvalidHTTPPort = 3

// // ServerErrCodeInvalidBinaryPort is bad binary port
// ServerErrCodeInvalidBinaryPort = 4

// // AccountErrCodeUnknown is unknown error
// AccountErrCodeUnknown = 1

// // AccountErrCodeBadFlags is user with bad flags
// AccountErrCodeBadFlags = 1

export const errors = {
  // Predefined errors
  invalidRequest: -32600,
  methodNotFound: -32601,
  invalidArgs: -32602,
  internalError: -32603,
  parseError: -32700,

  // RPC errors
  unauthorized: -32001, // User not found or wrong password
  accessDenied: -32002, // Method execution not allowed
  notFound: -32003, // Nothing found by requested params
  telematic: -32004, // Telematic error with additional description
  server: -32005, // Error code for server-related errors
  account: -32006, // Error code for account-related errors
  sync: -32007, // Error code appears when files sync happens
  uniqueViolation: -32008, // Additional data contains `constraintName` field
  system: -32009, // ErrorCodeSystem code appears when server detect some OS error or something
}

// Internal JS errors and codes
export const standardErrors = {
  Error: 'defaultError', // Undefined error
  RangeError: 'rangeError', // A number "out of range" has occurred
  ReferenceError: 'referenceError',//An illegal reference has occurred
  SyntaxError: 'syntaxError', // A syntax error has occurred
  TypeError: 'typeError', // A type error has occurred
  URIError: 'uriError', // An error in encodeURI() has occurred
}

export const getErrorMessage = (error, fallbackMessage, dictionary) => {
  const dict = Object.assign({}, dictionary)
  if (fallbackMessage == null) {
    fallbackMessage = error.message
  }

  let message = ''

  if (error.code != null) {
    message = dict[error.code]

    if (typeof message === 'object') {
      message = message[error.data?.code]
    }

    if (message != null) {
      return message
    }

    const addon = error.data != null && error.data.code != null ? `, ${error.data.code}` : ''
    return fallbackMessage + ` (${error.code}${addon})`
  }

  if (error.name === 'HTTPError' || error.response != null) {
    // TODO parse http error by response
    console.info('Got HTTPError', error)
  }

  message = dict[standardErrors[error.name]]
  if (message != null) {
    return message
  }
  return fallbackMessage
}