import React from 'react'


import { useStore } from '../../stores';
import { TagDrawer, TagFilter, AccountsFilter } from '../../components';
import { TAccount, TCategory, TAttribute } from '../../stores/interfaces';


// import styles from './Devices.module.css'


interface Props {
  title?: string
  accounts: TAccount[] | null
  categories: TCategory[] | null
  attributes: TAttribute[] | null
  show: boolean
  onHide?: () => void
  onAccountsChange: (accounts: string[]) => void
  selectedAccounts: string[]
  onTagsChange: (selected: number[]) => void
  selectedTags: number[]
}

export const DeviceFilterDrawer: React.FC<Props> = (props) => {
  const store = useStore()

  const hideDrawer = () => {
    if (props.onHide) {
      props.onHide()
    }
  }

  return <TagDrawer title={props.title} show={props.show} onHide={hideDrawer} full={store.width <= 920}>
    {props.accounts != null && props.accounts.length > 0 &&
      <AccountsFilter accounts={props.accounts} selected={props.selectedAccounts} onChange={props.onAccountsChange} />}

    <TagFilter
      categories={props.categories}
      attributes={props.attributes}
      selected={props.selectedTags}
      onChange={props.onTagsChange}
    />
  </TagDrawer>
}
