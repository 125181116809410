import React from 'react'
import { useLocalObservable } from 'mobx-react-lite'

import { createStore, DomainStore } from './store'

const storeContext = React.createContext<DomainStore | null>(null)

interface StoreProviderProps {
  children: React.ReactNode,
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const store = useLocalObservable(createStore)

  // if (process.env.NODE_ENV !== 'production') window.__STORE__ = store
  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider')
  }
  return store
}
