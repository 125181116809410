import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'rsuite'


import { errors, getErrorMessage } from '../common'


import { RPCError } from '../stores/interfaces'


type ErrorEntity = 'category' | 'attribute'
type EntityRequest = 'fetch' | 'create' | 'update' | 'delete'
type SetErrorFunc = (err: RPCError | null, entity?: ErrorEntity, request?: EntityRequest) => void

interface ErrorState {
  error: string | null
}

interface ErrorAction {
  error: string | null
}


const errorReducer = (state: ErrorState, action: ErrorAction): ErrorState => {
  return { error: action.error }
}

export const useError = (): { error: string | null, setError: SetErrorFunc } => {
  const { t } = useTranslation('errors')
  const [{ error }, dispatch] = React.useReducer(errorReducer, { error: null })

  const setError = (err: RPCError | null, entity?: ErrorEntity, request?: EntityRequest) => {
    if (err == null) {
      dispatch({ error: null })
      return
    }

    const targetEntity: string = entity != null ? entity : 'general'

    const dict = {
      [errors.invalidArgs]: t('general.invalidArgs'),
      [errors.internalError]: t('general.internalError'),
      [errors.invalidArgs]: t(`${targetEntity}.${request}.${errors.invalidArgs}`),

      [errors.server]: t(`${targetEntity}.${request}.${errors.server}`),
    }

    const errorMessage = getErrorMessage(err, t(`${targetEntity}.fallback`), dict)

    dispatch({ error: errorMessage })
  }

  return { error: error, setError: setError }
}

const alertReducer = (state: ErrorState, action: ErrorAction): ErrorState => {
  if (action.error != null && action.error.length > 0) {
    Alert.closeAll()
    Alert.error(action.error)
  }
  return { error: action.error }
}

export const useAlert = (): SetErrorFunc => {
  const { t } = useTranslation('errors')
  const [, dispatch] = React.useReducer(alertReducer, { error: null })

  const setError = (err: RPCError | null, entity?: ErrorEntity, request?: EntityRequest) => {
    if (err == null) {
      dispatch({ error: null })
      return
    }

    const targetEntity: string = entity != null ? entity : 'general'

    const dict = {
      [errors.invalidArgs]: t('general.invalidArgs'),
      [errors.internalError]: t('general.internalError'),
      [errors.invalidArgs]: t(`${targetEntity}.${request}.${errors.invalidArgs}`),

      [errors.server]: t(`${targetEntity}.${request}.${errors.server}`),
      [errors.uniqueViolation]: t(`${targetEntity}.${request}.${errors.uniqueViolation}`),
    }

    const errorMessage = getErrorMessage(err, t(`${targetEntity}.fallback`), dict)

    dispatch({ error: errorMessage })
  }

  return setError
}

// export const errors = {
//   // Predefined errors
//   invalidRequest: -32600,
//   methodNotFound: -32601,
//   invalidArgs: -32602,
//   internalError: -32603,
//   parseError: -32700,

//   // RPC errors
//   unauthorized: -32001, // User not found or wrong password
//   accessDenied: -32002, // Method execution not allowed
//   notFound: -32003, // Nothing found by requested params
//   telematic: -32004, // Telematic error with additional description
//   server: -32005, // Error code for server-related errors
//   account: -32006, // Error code for account-related errors
//   sync: -32007, // Error code appears when files sync happens
//   uniqueViolation: -32008, // Additional data contains `constraintName` field
// }