import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Dropdown, Icon, IconButton } from 'rsuite';


import { useStore } from '../../stores';
import { slugs } from '../../common';
import { UserIdentity } from '../UserIdentity';


import styles from './AppHeader.module.css';


import appHeaderImg from './ID_LOGO_2.png';


const items = [{
  id: 'settings',
  label: ' Настройки',
  path: slugs.main + '/0' + slugs.settings,
  icon: 'cogs',
}, {
  id: 'signout',
  label: ' Выйти',
  path: slugs.logout,
  icon: 'sign-out',
}]

const AppLink = ({ title, icon, path, hint }) => {
  // const onClick = () => {
  //   window.location
  // }
  // return <Link to={path} target='_blank'><Icon icon={icon} />{title}</Link>
  return <a rel='noopener noreferrer' target='_blank' href={path} title={hint}><Icon icon={icon} /> {title}</a>
  // return <Whisper
  //   trigger='hover'
  //   placement='auto'
  //   speaker={<Popover><p>{title}</p></Popover>}
  // >
  //   <IconButton appearance='ghost' icon={<Icon icon={icon} />} circle />
  // </Whisper>
}

const AppHeader = (props) => {
  const store = useStore()
  const history = useHistory()

  const navigate = (path) => () => {
    history.push(path)
  }

  if (store.core.current == null) return null

  const userID = store.core.current.ownerID === store.core.invoker.userID ? 0 : store.core.current.ownerID
  const mapsUserID = store.core.current.userID === store.core.invoker.userID ? 0 : store.core.current.userID
  const parentPath = `${slugs.main}/${userID}${slugs.clients}`
  // const toUpperLevel = () => {
  //   history.push(parentPath)
  // }

  // const label = store.core.invoker.label ||
  //   `${store.core.invoker.firstName} ${store.core.invoker.lastName}`

  const isInvoker = store.core.current.userID === store.core.invoker.userID

  return <header className='header'>
    <div className={styles.printHeader}><h4>Locarus ID</h4></div>

    <div className={styles.headerWrapper}>
      {/* {!isInvoker && <div className={styles.clientSection}>
        <UserIdentity user={store.core.current} className={styles.clientCaption} />

        
        <a href={parentPath}>
          <IconButton appearance='ghost' icon={<Icon icon='level-up' />}>
            На уровень выше
          </IconButton>
        </a>
      </div>} */}

      <div className={styles.leftSection}>
        <div className={styles.menuButton}>
          <Icon icon='bars' size='2x' onClick={() => { store.core.setSideMenuState(true) }} />
        </div>

        <div>
          <Link to={slugs.main + '/0'} className={styles.cleanLink}>
            <img className={styles.headerImg} src={appHeaderImg} alt='appLogo' />
          </Link>
        </div>

        {/* {!isInvoker && <div className={styles.clientSection}> */}
        {/* <Link to={parentPath}> */}
        {/* <a href={parentPath}>
            <IconButton appearance='ghost' icon={<Icon icon='arrow-circle-o-up' />}>
              На уровень выше
          </IconButton>
          </a> */}
        {/* </Link> */}
        {/* <UserIdentity user={store.core.current} className={styles.clientCaption} /> */}
        {/* </div>} */}

        {/* <div>
      <Link to={`${slugs.main}/0${slugs.dashboard}`} style={{ textDecoration: 'none!important', color: 'rgb(95, 235, 91)' }}>
        <Button appearance="ghost" color='orange'>На дашборд (?)</Button>
      </Link>
    </div> */}

        {/* <div>
      <Breadcrumbs routes={props.routes} />
    </div> */}

      </div>

      <div className={styles.rightSection}>
        <div className={styles.appsSection}>
          <AppLink
            title='Maps'
            icon='map-o'
            path={`${slugs.mapsPath}/${mapsUserID}`}
            hint='Перейти с этим аккаунтом в Locarus Maps'
          />
        </div>

        {/* <Dropdown
          placement='bottomEnd'
          renderTitle={() => <IconButton appearance='ghost' icon={<Icon icon="th2" />} circle />}
        >
          <div>
            <Dropdown.Item>
              <AppLink title='Maps' icon='map-o' path={'http://stage.local/maps'} />
            </Dropdown.Item>

            <Dropdown.Item>
              <AppLink title='Reports' icon='building2' />
            </Dropdown.Item>

            <Dropdown.Item>
              <AppLink title='ID' icon='sitemap' />
            </Dropdown.Item>
          </div>
        </Dropdown> */}

        <Dropdown
          placement='bottomEnd'
          renderTitle={() => <IconButton icon={<Icon icon="user-circle-o" />} circle />}
        >
          <div style={{ padding: '20px' }}>
            <div className={styles.userSection}>
              <UserIdentity user={store.core.invoker} />
            </div>

            {items.map((item) => {
              if (store.core.invoker.isLocked && item.id === 'settings') {
                return null
              }

              return <Dropdown.Item key={`user-menu-${item.id}`} onSelect={navigate(item.path)}>
                <Icon icon={item.icon} /> {item.label}
              </Dropdown.Item>
            })}
          </div>
        </Dropdown>
      </div>
    </div>

    {!isInvoker && store.width <= 920 && <div className={styles.clientSection}>
      <div className={styles.headerWrapper}>
        <div className={styles.leftSection}>
          {/* FIXME that's a dirty hack, rewrite */}
          <a href={parentPath}>
            <IconButton appearance='ghost' icon={<Icon icon='level-up' />}>
              Наверх
            </IconButton>
          </a>

          <UserIdentity user={store.core.current} className={styles.clientCaption} />
        </div>
      </div>
    </div>}
  </header>
}

export default observer(AppHeader)
