import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup, Input, Icon } from 'rsuite';

const SearchBar = ({ placeholder, ...props }) => {
  return <InputGroup {...props} inside>
    <Input placeholder={placeholder} />
    <InputGroup.Addon>
      <Icon icon='filter' />
    </InputGroup.Addon>
  </InputGroup>
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
}

SearchBar.defaultProps = {
  placeholder: '',
}

SearchBar.displayName = 'SearchBar'

export default SearchBar;
