import React from 'react'
import { Button, Icon } from 'rsuite'

interface TagButtonProps {
  icon?: 'close' | 'plus' | 'edit'
  clear?: boolean
  onClick: () => void
  children: string
}

const tbIconStyle = { fontSize: '0.9em' }

export const TagButton: React.FC<TagButtonProps> = ({ clear, onClick, ...props }) => {
  return <Button size='xs' appearance={clear ? 'ghost' : 'primary'} color={clear ? undefined : 'orange'} onClick={onClick}>
    {props.children} {props.icon != null && <Icon icon={props.icon} style={tbIconStyle} />}
  </Button>
}
