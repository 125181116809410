import React from 'react';
import { Icon } from 'rsuite';


import styles from './EmptyList.module.css';


interface EmptyListProps {
  placeholder: string,
}


const EmptyList = (props: EmptyListProps) => {

  return <div className={styles.block}>
    <div>
      <Icon icon='inbox' size='lg' />
    </div>
    <div className={styles.gapped}>
      <span>{props.placeholder}</span>
    </div>
  </div>
}

export default EmptyList;
