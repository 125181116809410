import { useState, useEffect, useReducer } from 'react';
import ky from 'ky';

import { prod } from '../common'

const generateRequestID = () => {
  return Math.floor(Math.random() * 1000000).toString()
}

const defaultOptions = {
  request: { method: '' },
  initialData: null,
  loading: false,
}

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        loading: true,
        error: null
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        error: null,
        response: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        loading: false,
        error: action.payload,
        response: null,
      };
    default:
      throw new Error();
  }
};

export const apiCall = async (method, params) => {
  const requestOptions = {
    method: 'post',
    json: {
      jsonrpc: '2.0',
      id: generateRequestID(),
      method: method,
    }
  }

  if (params) {
    requestOptions.json.params = params
  }

  return ky('/api/', requestOptions).json()
}

export const useApi = (options) => {
  const stateConfig = Object.assign({}, defaultOptions, options);

  const [request, setRequest] = useState(stateConfig.request);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    loading: stateConfig.loading,
    error: null,
    response: stateConfig.initialData,
  });

  useEffect(() => {
    if (request.method === '') return

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const response = await apiCall(request.method, request.params);

        !prod && console.info('API RESPONSE', response)

        // FIXME remove synthetic timeout
        // setTimeout(() => {
        if (response.error) {
          dispatch({ type: 'FETCH_FAILURE', payload: response.error });
        } else {
          dispatch({ type: 'FETCH_SUCCESS', payload: response.result });
        }
        // }, 3000);

      } catch (error) {
        // TODO codes
        dispatch({ type: 'FETCH_FAILURE', payload: { code: -1, message: error.message } });
      }
    };

    fetchData();

    // setError(null)
    // setLoading(true)

    // apiCall(request.method, request.params)
    // .then((resp) => {
    //   console.info('received', resp)
    //   setResponse(resp)
    // })
    // .catch((err) => {
    //   setError(err)
    // })
    // .finally(() => {
    //   setLoading(false)
    // })

    // const doFetch = async () => {
    //   setError(null)
    //   setLoading(true)

    //   const requestOptions = {
    //     method: 'post',
    //     json: {
    //       jsonrpc: '2.0',
    //       id: generateRequestID(),
    //       method: request.method,
    //     }
    //   }

    //   if (request.params) {
    //     requestOptions.json.params = request.params
    //   }

    //   try {
    //     const resp = await ky('/api', requestOptions).json()
    //     console.info('received', resp)
    //     setResponse(resp)
    //   } catch (err) {
    //     // TODO parse erorrs by type sych as 'HTTPError' and other
    //     setError(err)
    //   }

    //   setLoading(false)
    // }

    // doFetch()
  }, [request.method, request.params]);

  return [state, setRequest];
}
